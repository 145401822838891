@import "src/assets/sass/gle/mixins";

.RecipeEditorImage{
  .image-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include fillParent;
    width: 90%;
    left: 5%;
    border-radius: 15px;
    overflow: hidden;
  }
  .click-overlay{
    @include fillParent;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white;
    opacity: 0;
    transition: opacity 0.2s;
  }
  &.no-image .click-overlay{
    opacity: 0.6;
  }
  &:hover .click-overlay{
    opacity: 0.8;
  }
  .file-input{
    @include fillParent;
    opacity: 0;
    cursor: pointer;
  }
  .print-photo{
    max-width: 100%;
  }
}
