.RecipeHeader{
  display: flex;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
  .table {
    border: none;
    margin: 0;
    tr:first-child {
      border: none;
      background: linear-gradient(to right, #b58771 75%, #ada86a 100%);
      color: white;
      font-size: 1.5em;
      td{ border: none}
    }
  }
  .left{
    flex:8;
    border: 3px solid #633414;
    border-radius: 20px 0 20px 0;
    overflow: hidden;
    position: relative;
    .edit-btn{
      position: absolute;
      top: 9px;
      right: 9px;
      background: rgba(255,255,255,0.7s);
      border: none;
      font-size: 1.5em;
      width: 1.5em;
      border-radius: 4px;
      &:hover{
        background: white;
      }
    }
    .approve-btn{
      position: absolute;
      top: 9px;
      right: 51px;
      background: rgba(255,255,255,0.7s);
      border: none;
      font-size: 1.5em;
      width: 1.5em;
      border-radius: 4px;
      &:hover{
        background: white;
      }
    }
  }
  .right{
    flex:4;
    position: relative;
    min-height: 250px;
    @media screen and (max-width: 768px){
      margin-top: 20px;
    }
  }
}
