.InventoryDetailModal {
  .showInWarehouseCount-block {
    padding: 15px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    .b-label {
      font-size: 1.1em;
    }
  }
  .reorderPoint {
    border: 1px solid gray;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    margin-right: 10px;
  }

  .select-box-reorderpoint {
    border: 1px solid gray;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 14px;
  }

  .reorderPointUnit {
    border: 1px solid gray;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-button-rop {
    background-color: slateblue;
    color: white;
    border: none;
    margin-left: 15px;
    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: slateblue;
    }
  }

  .save-button-rop {
    background-color: green;
    color: white;
    border: none;
    margin-left: 15px;
    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: green;
    }
  }

  .reorderUnitsContainer {
    display: flex;
    justify-content: center;
  }

  .reorderInput {
    display: flex;
    flex-direction: column;
  }

  .reorderPointLabel {
    width: 100%;
    text-align: left;
  }

  .delete-button {
    border: none;
    background-color: red;
    color: white;
    font-weight: bolder;
    padding: 10px 15px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .title-container {
    position: relative;
  }

  .ReactTable{
    & .-totalPages{
      display: none;
    }
  }


}
