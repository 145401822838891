.RecipeIngredientsTable{
  &.loader{
    margin: auto;
    max-width: 300px;
  }
  .no-ingredients{
    text-align: center;
    margin: 20px auto;
    max-width: 340px;
    background-color: lightgray;
    border-radius: 4px;
    padding: 20px;
    color: white;
  }
}
