.LoungeMapEditor{
  margin: 15px;
  height: calc(100vh - 125px);

  .LoungeMap{
    float: left;
    width: calc(100% - 300px);
    height: 100%;
    min-height: 500px;
  }
  .LoungeMapEditorTools{
    float: left;
  }
}
