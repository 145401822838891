.Holidays{
    
}

.label--w100{
    width: 100%;
}

.btn-mt-1{
    margin-top: 15px;
}