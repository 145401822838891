.FlightForm {
    .full-width {
        width: 100%;
    }
    fieldset {
        .label-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 320px;
            label {
                text-align: center;
                input {
                    width: 150px;
                    color: black;
                }
            }
        }
    }
    .destination-type{
        margin-top: 5%;
    }
}