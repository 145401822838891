.RecipeModifiers{
  margin-top: 15px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 15px;
  .title{
    margin: 0;
  }
  .modifiers-list{
    list-style: none;
    padding: 15px 0;
    .modifier{
      display: inline-block;
      border: 1px solid #b58771;
      padding: 5px 15px;
      border-radius: 5px;
      margin: 0 10px;
      cursor: pointer;
      i{
        color: green;
      }
      &.missing i{
        color: red;
      }
      &:hover i{
        transform: scale(1.3);
      }
      &.active{
        color: white;
        background: linear-gradient(to right, #b58771 75%, #ada86a 100%);
      }
    }
  }
}
