.Nps{
  .face{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 0 auto;
    & svg{
      & > *{
        fill: black;
      }
    }
   &.sad{
        background-color: #F32013;
   }
    &.regular{
      background-color: #F0D500;
    }
    &.happy{
      background-color: #4BB543;
    }
  }
  .row-gutter{
    margin: 20px;
  }
}