.TableConfigurator{
  .column-block{
    padding: 5px;
    .column-block-inner {
      border: 1px solid gray;
      border-radius: 5px;
      padding: 4px;
    }
  }
}
