.GolfCartScheduleControlPanel{
  min-width: 200px;
  overflow: auto;
  .not-selected{
    text-align: center;
    padding-top: 100px;
    p{
      color: lightgrey;
      font-size: 1.4em;
      margin-top: 20px;
    }
  }
  .content{
    //max-height: calc(100vh - 160px);
    //overflow: auto;
  }
}
