.GolfCartTripModalData {
  .data-title {
    margin: 0;
  }

  .list-data {
    padding-left: 20px;

    .list-item-data {
      margin: 0;
    }
  }
}