.DayOfTheWeekSelector{
  display: flex;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  .day-but{
    flex: 1;
    padding: 5px;
    background-color: white;
    border: none;
    border-right: 1px solid #E3E3E3;
    &:last-child{
      border: none;
    }
    &.active{
      color: white;
      background: linear-gradient(to bottom, rgba(31, 141, 255, 0.63) 50%, rgba(147, 243, 255, 0.76) 100%)
    }
  }
}
