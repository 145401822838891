.StockRequestGenerator{
  margin: 10px 0;
  .auto-form{
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .form-row{
    text-align: center;
  }
  .field-name{
    font-size: 1.2em;
  }
}
