.RecipeFormModal{

  .unit-label{
    position: absolute;
    top: 1.2em;
    right: 1em;
    pointer-events: none;
    font-size: 1.2em;
    color: #888888;
  }
}
