.SingleWarehouseConfig{
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  .wh-name{
    font-size: 1.5em;
    margin: 0;
    cursor: pointer;
    &.inactive{
      color:red;
    }
  }
  .config{
    .btn{
      margin-right: 15px;
    }
  }
}
