.KatharsisReports{
  .card {
    padding: 15px;
  }
  .report-block{
    border: 1px solid #e5e5e5;
    padding: 10px;
    border-radius: 5px;
    .title{
      font-size: 1.2em;
      cursor: pointer;
    }
    .filter-label{
      margin: 10px 0 4px 0;
      font-size: 1.1em;
    }
    .description{
      border: 1px solid #e5e5e5;
      padding: 10px;
      border-radius: 5px;
    }
    .caret-icon{
      color: #797979;
      float: right;
      transition: transform 0.3s;
    }
    &.open .caret-icon{
      transform: rotate(180deg);
    }
  }
}
