.ElementOrder{
  ul{
    list-style: none;
    max-height: 400px;
    overflow: auto;
  }
}

.ElementOrder-element{
  padding: 4px;
  border: 1px solid white;
  margin: 2px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  list-style: none;

  &.selected{
    background-color: white;
    color: black;
  }
}
