.RecipeFooter{

  .instructions{
    position: relative;
    font-size: 1.2em;
    padding: 15px;
    background: url("../../assets/old-paper-back.jpg");
    background-size: 100% 100%;
    border-radius: 10px;
    margin: 15px 0;
    .text{
      font-size: 1em;
      padding: 0;
      background: none;
      border: none;
    }
    .textarea{
      display: block;
      width: 100%;
      min-height: 120px;
    }

    .edit-btn{
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(255, 255, 255, 0.7);
      border: none;
      font-size: 1.5em;
      width: 1.5em;
      border-radius: 4px;
      &.cancel{
        right: 43px;
      }
    }
  }
}
