.VisitsFlightsReport {
  min-height: 500px;

  .overflow-table {
    overflow: scroll;
  }
}

.GuestsFlightsGoalReport {

  .export-button {
    button {
      margin-top: 10px;
      width: 100%;
      border-color: #447DF7;
      color: #447DF7;
    }
  }
}