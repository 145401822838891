.AirlinesManagement {
    margin: 10px 2%;
    .edit-action{
        border: none;
        background: none;
        width: 100%;
    }
    input.search {
        margin-bottom: 7px;
    }
}