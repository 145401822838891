.GolfCartScheduleEditControl{
  overflow: auto;
  .card{
    margin: 0;
  }
  .trip-editor{
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    padding: 5px 15px 15px;
    position: relative;
    .delete-button{
      position: absolute;
      right: 0;
      top: 0;
      background: none;
      border: none;
    }
  }
  .add-button{
    width: 100%;
  }

}
