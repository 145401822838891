.DestinationTypesManagement {
    max-width: 1080px;
    margin: auto;
    padding: 2%;
    width: 100%;

    header {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    ul {
        margin: 0;
        padding: 0;
        #list-headers {
            border: none;
        }
        li {
            border-bottom: 1px solid lightgray;
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            margin-bottom: 9px;

            p {
                margin: 0;
            }
            .actions button {
                border: none;
                background: none;
                padding: 0;
                margin: 0 5px;
                opacity: 0.6;
                transition: all .2s ease-in-out;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}