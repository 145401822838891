a {
  cursor: pointer; }

.checkbox + .checkbox, .radio + .radio {
  margin-top: 10px; }

pre {
  word-break: keep-all;
  white-space: pre-wrap; }

.main-panel {
  padding-bottom: 30px;
  height: auto;
  max-height: initial; }

.main-container {
  padding-top: 20px; }

.main-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 50px;
  background-color: white; }

.navbar .navbar-brand {
  text-transform: none; }

.page-version {
  z-index: 2;
  position: fixed;
  color: #808080;
  bottom: 0;
  left: 0; }

.loading-notification {
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 1em;
  padding: 8px 40px;
  background-color: #ffa434;
  border-radius: 4px;
  border: 1px solid #dddddd;
  z-index: 10000;
  color: white; }

.single-name-table {
  border: 1px solid #DDDDDD;
  cursor: pointer;
  text-align: center; }
  .single-name-table:hover {
    background-color: #F8F8F8; }
  .single-name-table .fa {
    visibility: hidden; }
  .single-name-table:hover .fa {
    visibility: visible; }
  .single-name-table .fa-trash:hover {
    color: red; }
  .single-name-table .fa-pencil:hover {
    color: #447DF7; }

.red {
  color: red !important; }

.green {
  color: green !important; }

.orange {
  color: #cf7300 !important; }

.pink {
  color: #ff6f6f !important; }

.white {
  color: #FFFFFF !important; }

.sky-blue {
  color: #4db1e7 !important; }

.span-but {
  cursor: pointer; }
  .span-but:hover {
    text-decoration: underline; }

/* Fix theme bug when image is not found */
.card-user .avatar {
  min-width: 124px;
  min-height: 124px; }

/* React Table Styles */
.ReactTable {
  border: none; }
  .ReactTable .rt-thead .rt-resizable-header-content {
    background: linear-gradient(60deg, #2496d8, #62c1ef);
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 10px;
    color: white;
    border: none; }
  .ReactTable .rt-tr {
    text-align: left !important; }
  .ReactTable .rt-td {
    padding: 10px 5px; }
  .ReactTable .-pagination {
    box-shadow: none; }
    @media only screen and (max-width: 600px) {
      .ReactTable .-pagination {
        flex-direction: column; } }
  .ReactTable .red-table-row {
    background-color: #ffc8c8 !important; }
    .ReactTable .red-table-row:hover {
      background-color: #ff7d7d !important; }
  .ReactTable .checkbox {
    margin: 0; }

.notifications-wrapper pre {
  background-color: transparent;
  color: white; }

.notifications-wrapper .notification-message a {
  color: white;
  font-weight: bold; }

.pointer {
  cursor: pointer; }

/* All modals style */
.modal-header {
  background: linear-gradient(60deg, #2496d8, #62c1ef);
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 5px 8px;
  color: white;
  border: none;
  margin: 5px; }
  .modal-header h4 {
    font-size: 20px; }
  .modal-header .close {
    color: white;
    opacity: 1;
    margin-top: 3px; }

.ModalIframe iframe, .ModalIframe img {
  width: 100%; }

.ModalIframe iframe {
  min-height: 60vh; }

.FileViewer img {
  max-width: 100%; }

.FileViewer iframe {
  width: 100%;
  min-height: 60vh; }

/* Make dashboard cards responsive */
@media (max-width: 1470px) {
  .card-stats .numbers {
    font-size: 1.5em; } }

@media (max-width: 1250px) {
  .card-stats .numbers {
    font-size: 1.3em; } }

@media (max-width: 1200px) {
  .card-stats .numbers {
    font-size: 2em; } }

.input-group-addon {
  background-color: #e6e6e6; }

.react-tagsinput-input {
  width: 220px; }

.react-tagsinput-tag {
  text-transform: none; }

.link {
  cursor: pointer;
  color: #23CCEF; }

.DateRangePickerInput {
  border-radius: 5px; }

.DateInput {
  background: none; }

.DateInput_input {
  background: none;
  font-size: 14px;
  padding: 3px 3px 3px 26px; }

body.with-modal {
  overflow: hidden; }

.sidebar[data-color="red"]:after, .bootstrap-navbar[data-color="red"]:after {
  background: linear-gradient(to bottom, #f50039 50%, #000 100%); }

.sidebar[data-color="greenyellow"]:after, .bootstrap-navbar[data-color="red"]:after {
  background: linear-gradient(to bottom, #8eb100 50%, #171616 100%); }

.sidebar[data-color="whitesmoke"]:after, .bootstrap-navbar[data-color="red"]:after {
  background: whitesmoke; }

.sidebar[data-color="purple"]:after {
  background: linear-gradient(to bottom, rgba(247, 182, 255, 0.63) 75%, rgba(255, 255, 255, 0.56) 100%); }

.sidebar[data-color="lightBrown"]:after {
  background: linear-gradient(to bottom, #b58771 75%, #ada86a 100%); }

.red-icon {
  color: red;
  cursor: pointer; }

.profile-completion-image {
  margin: auto; }

.big-button {
  font-size: 18px;
  margin-right: 20px;
  width: 100%;
  margin-top: 10px; }
  .big-button.btn-info.active, .big-button.btn-info:hover, .big-button.btn-info.active:hover {
    background-color: #11bfe3;
    color: white; }
  .big-button.btn-success.active, .big-button.btn-success:hover, .big-button.btn-success.active:hover {
    background-color: #78b414;
    color: white; }
  .big-button.btn-warning.active, .big-button.btn-warning:hover, .big-button.btn-warning.active:hover {
    background-color: #FFA534;
    color: white; }

.button-left {
  border-radius: 5px 0 0 5px; }

.button-right {
  border-radius: 0 5px 5px 0; }

.button-clear-def {
  border: none;
  background: none; }

span.dot {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  padding: 0;
  border-radius: 1em;
  background-color: grey;
  margin: 0.08em 0.2em; }
  span.dot.red {
    background-color: red; }
  span.dot.green {
    background-color: greenyellow; }

.scrollable-table-container {
  width: 100%;
  overflow-x: auto;
  padding: 0 1px; }

@media only screen and (max-width: 767px) {
  .vertical-responsive-table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0; }
    .vertical-responsive-table thead {
      display: none; }
    .vertical-responsive-table tr {
      border: 1px solid #ddd;
      border-bottom: 2px solid #ddd;
      padding: 5px;
      margin-bottom: 10px;
      display: block; }
    .vertical-responsive-table th,
    .vertical-responsive-table td {
      padding: 10px;
      text-align: left; }
    .vertical-responsive-table th {
      text-transform: uppercase;
      font-size: 11px; }
    .vertical-responsive-table td {
      display: block;
      text-align: right;
      font-size: 13px;
      border-bottom: 1px dotted #ddd; }
      .vertical-responsive-table td:last-child {
        border-bottom: none; }
      .vertical-responsive-table td input, .vertical-responsive-table td select {
        display: inline-block;
        text-align: right;
        width: initial; }
    .vertical-responsive-table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold; } }

.alert-primary {
  background-color: #2c6cf6; }

.dropdown.btn-group:not(.open) {
  overflow: hidden; }

@media only print {
  .print-none {
    display: none; } }

.print-only {
  display: none; }
  @media only print {
    .print-only {
      display: block; } }

.InteractiveReport table tbody td, .InteractiveReport table tbody th {
  white-space: nowrap; }

.SingleDatePicker-form-control .SingleDatePicker, .SingleDatePicker-form-control .SingleDatePickerInput, .SingleDatePicker-form-control .DateInput, .SingleDatePicker-form-control .DateInput_input {
  display: block;
  width: initial; }

.SingleDatePicker-form-control .SingleDatePickerInput {
  border-radius: 5px; }

.SingleDatePicker-form-control .DateInput_input {
  padding: 8px;
  width: 100%; }

.p-relative {
  position: relative; }

.full-width {
  width: 100%; }

.error-message {
  color: red; }

.logout-wrap {
  padding-top: 100px;
  color: white;
  text-align: center; }

.sidebar .nav i {
  font-size: 20px; }

.sidebar .logo a.logo-normal {
  text-align: center;
  position: relative; }
  .sidebar .logo a.logo-normal img {
    max-width: 90%;
    max-height: 49px; }
  .sidebar .logo a.logo-normal.option {
    transition: max-height 0.4s;
    max-height: 0;
    overflow: hidden;
    padding: 0; }
  .sidebar .logo a.logo-normal i.caret {
    position: absolute;
    bottom: 5px;
    left: 5%; }

.sidebar .logo.open a.logo-normal.option {
  max-height: 100px; }

.sidebar .logo.open a.logo-normal i.caret {
  transform: rotate(180deg); }

.sidebar .logo-mini {
  display: none !important; }

.sidebar .sidebar-wrapper {
  height: calc(100% - 83px); }

.sidebar .user .photo, .sidebar .bootstrap-navbar .user .photo {
  width: 36px;
  height: 36px;
  border: 3px solid rgba(255, 255, 255, 0.3); }

.sidebar-mini .sidebar .logo-mini {
  display: block !important; }

.sidebar-mini .sidebar:hover .logo-mini {
  display: none !important; }

.sidebar[data-color="azure"]:after, .bootstrap-navbar[data-color="azure"]:after {
  background: linear-gradient(to bottom, rgba(31, 141, 255, 0.63) 75%, rgba(147, 243, 255, 0.76) 100%); }

.sidebar:before, .bootstrap-navbar:before {
  opacity: .82; }

@media (max-width: 992px) {
  .off-canvas-sidebar .navbar-collapse,
  .navbar-collapse.collapse.in {
    display: none !important; } }

.UploadTimeRegister .panel {
  margin-top: 20px;
  text-align: center; }

.UploadTimeRegister .panel-body {
  text-align: center; }
  .UploadTimeRegister .panel-body input {
    display: inline; }
  .UploadTimeRegister .panel-body .instructions {
    font-size: 14px; }
  .UploadTimeRegister .panel-body .sideIcon {
    font-size: 130px; }

.UploadTimeRegister .report-stats {
  border: 1px black solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px; }

.UploadTimeRegister .report-details .panel-heading {
  cursor: pointer; }

.employee-history h4 {
  margin-top: 0; }

@media screen and (min-width: 1200px) {
  .employee-history {
    padding: 20px; } }

.employee-history .select-date-button {
  text-transform: capitalize; }

.employee-history .days-select {
  display: inline-block;
  width: 90px;
  background-color: transparent;
  margin-left: 30px;
  padding: 0; }

.employee-history .registerList {
  list-style: none;
  padding: 0; }

.employee-history .rt-td {
  position: relative; }

.employee-history .cell-left {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  text-transform: capitalize; }

.employee-history .next-page-but {
  float: right; }

.employee-history .yellow {
  color: #ff9f47; }

.employee-history .red {
  color: #ff2222; }

.employee-history .orange {
  color: #ff5922; }

.employee-history .brown {
  color: #a52a2a; }

.employee-history .green {
  color: #45b73f; }

.main-description {
  text-align: center; }
  .main-description span {
    max-width: 500px;
    display: inline-block; }

span.shiftDay {
  font-weight: bold;
  font-size: 1.2em; }

span.shiftDesc {
  display: inline-block;
  min-width: 140px; }

.delete-button {
  margin: 10px; }

.DaySchedule {
  margin: 5px;
  border: 1px solid lightgray;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 #afafaf inset; }

.ListEmployees .total-count {
  margin: 20px 0;
  font-size: 1em;
  color: #a7a7a7; }

.ListEmployees .list-photo-container {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  margin: auto; }
  .ListEmployees .list-photo-container img {
    width: 100%; }

.card {
  margin-top: 20px; }

.outsourcing .control-label {
  margin-left: 15px; }

.outsourcing .checkbox {
  margin: 5px 0 0 10px; }

.form-video {
  text-align: center; }

.payroll-detail {
  padding: 10px 10px; }
  .payroll-detail .justify-but {
    text-decoration: underline;
    color: #00bbff;
    cursor: pointer; }
  .payroll-detail .justify-modal textarea {
    width: 100%; }
  .payroll-detail .approve-payroll {
    margin-top: 30px; }
  .payroll-detail .export-button {
    margin-top: 15px; }

.UserDetail h4 {
  text-align: center; }

.UserDetail .info-label {
  text-align: right; }

.UserDetail .card-user .content {
  min-height: 216px; }

.IncidenceJustificationDetail .FileViewer {
  text-align: center; }

.JustificationRequestDetail .FileViewer {
  text-align: center; }

.PayrollFileHistory h4 {
  display: inline-block;
  margin-right: 50px; }

.EmployeeJustifications h4 {
  margin-left: 20px; }

.EmployeeIncidenceRequests button.top-button {
  margin-top: 20px; }

.EmployeeIncidenceRequests h4 {
  margin-left: 20px; }

.Dashboard .echarts-for-react {
  box-shadow: 5px 8px 12px #cacaca; }

.Help .screen-img {
  margin: 20px 0; }

.navbar .new-version-header-link {
  background-color: #6bff62; }

[class*="navbar-"] .navbar-nav .dropdown-menu > li > a,
[class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a {
  color: gray; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-menu > li.selected > a {
  background: linear-gradient(60deg, #2496d8, #62c1ef) !important;
  color: white !important;
  box-shadow: 0 4px 4px 0 #d4d4d4; }

.nav.navbar-nav {
  float: right; }
  .nav.navbar-nav .notification-desc {
    display: block; }
  .nav.navbar-nav .notification-time {
    font-size: 12px; }
    .nav.navbar-nav .notification-time .time-string {
      display: inline-block; }
      .nav.navbar-nav .notification-time .time-string:first-letter {
        text-transform: capitalize; }
  .nav.navbar-nav li.unread {
    background-color: #f0eef3;
    position: relative; }
    .nav.navbar-nav li.unread:before {
      content: " ";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #ff727b;
      position: absolute;
      top: 16px;
      left: 8px; }

.navbar .container-fluid .nav .dropdown [aria-labelledby="basic-nav-dropdown-2"] {
  overflow: scroll;
  overflow-x: hidden;
  height: 25vw; }

@media (max-width: 992px) {
  .navbar .container-fluid .nav .dropdown [aria-labelledby="basic-nav-dropdown-2"] {
    overflow: auto;
    height: auto; }
  .mobile-notifications-icon {
    display: inline-block;
    float: right;
    margin: 20px 5px 0 0;
    position: relative; }
    .mobile-notifications-icon .fa {
      color: #5ebeed;
      font-size: 20px; }
    .mobile-notifications-icon .notification-badge {
      position: absolute;
      display: block;
      top: -8px;
      left: -10px;
      background-color: #ff717a;
      min-width: 20px;
      height: 20px;
      border-radius: 10px;
      color: white;
      text-align: center; }
  .nav.navbar-nav ul:before {
    content: none !important; }
  .nav.navbar-nav ul:after {
    content: none !important; }
  .nav.navbar-nav .dropdown-toggle {
    display: none; }
  .nav.navbar-nav .notification-desc {
    white-space: normal; }
  .nav.navbar-nav li.unread:before {
    top: 22px;
    left: 10px; }
  .navbar-nav .dropdown-menu {
    max-height: 0;
    transition: 1s max-height;
    overflow: auto;
    display: block;
    box-shadow: none;
    border: none;
    position: relative; }
    .navbar-nav .dropdown-menu p {
      white-space: nowrap; }
    .navbar-nav .dropdown-menu > li > a {
      padding: 15px 15px 5px 20px !important; }
  .navbar-nav .open .dropdown-menu {
    max-height: 90vh; } }

.EmployeeForm input[type=file] {
  display: inline;
  border: 1px dimgrey solid;
  padding: 5px;
  border-radius: 4px;
  background-color: #f7f7f7; }

.EmployeeForm .bonus-field {
  min-height: 100px; }

.EmployeeIncidences .name-title {
  padding: 0 30px; }

.applied-incidence-summary {
  overflow: auto; }
  .applied-incidence-summary th, .applied-incidence-summary td {
    text-align: center; }

.EmployeeWorkTools .ewt-item {
  border: 1px solid #e0e0e1;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px; }
  .EmployeeWorkTools .ewt-item button {
    margin-right: 10px; }

.EmployeeWorkTools .assign-btn {
  margin-top: 20px; }

.PersonnelRequisitions .ReactTable {
  margin-top: 20px; }

.PersonnelRequisitions .new-requisition {
  margin-top: 10px; }

.PersonnelRequisitionsForm label {
  font-weight: 400; }

.Questionnaire .block-with-group {
  background: #fbfbfb;
  border-radius: 5px;
  margin: 20px 0;
  padding: 1px 15px;
  box-shadow: -5px 5px 11px 3px rgba(0, 0, 0, 0.23); }
  .Questionnaire .block-with-group h4 {
    margin: 10px 0; }

.Questionnaire .whole-score {
  margin: 0; }

.answer-component {
  display: inline-block; }
  .answer-component button {
    outline: 0;
    background-color: #DDDDDD; }
    .answer-component button.left {
      border-radius: 5px 0 0 5px; }
    .answer-component button.right {
      border-radius: 0 5px 5px 0; }

/*----- Answer Types -----*/
.YesNoAnswer button {
  width: 60px;
  height: 40px; }
  .YesNoAnswer button.yes {
    border-radius: 5px 0 0 5px; }
    .YesNoAnswer button.yes.active {
      background-color: #00dd81; }
  .YesNoAnswer button.no {
    border-radius: 0 5px 5px 0; }
    .YesNoAnswer button.no.active {
      background-color: #f54c5c; }
  .YesNoAnswer button.active {
    box-shadow: inset -3px 4px 10px 0px rgba(0, 0, 0, 0.4);
    color: white; }

.NumberAnswer button, .PercentageAnswer button {
  width: 60px;
  height: 40px; }

.NumberAnswer input, .PercentageAnswer input {
  height: 40px;
  width: 80px;
  position: relative;
  top: 1px;
  text-align: center; }

.PercentageAnswer {
  overflow: hidden;
  width: 100%; }
  .PercentageAnswer .title {
    min-width: 50px;
    display: inline-block;
    font-size: 16px; }
  .PercentageAnswer .the-bar {
    height: 10px;
    background-color: #b4ff2f; }

.MultipleChoiceRadioAnswer button {
  padding: 10px;
  margin: 2px;
  border-radius: 5px; }
  .MultipleChoiceRadioAnswer button.active {
    background-color: #567c92;
    color: white; }

@media only screen and (min-width: 1000px) {
  .LongTextAnswer textarea {
    width: 40vw;
    height: 5vw; } }

.FileAnswer ul {
  padding: 0;
  list-style: none; }

.FileAnswer img {
  max-width: 100%; }

/*----- Configurators -----*/
.RangesValues ul, .OptionsValues ul {
  padding: 0;
  list-style: none; }

.RangesValues .range-title, .OptionsValues .range-title {
  font-size: 16px;
  color: gray; }

.OptionsValues li span {
  color: gray;
  font-size: 16px;
  padding: 3px;
  border: 1px solid #e4e4e5;
  margin: 6px;
  display: inline-block;
  border-radius: 4px;
  min-width: 30px; }

.OptionsValues li input {
  max-width: 100px;
  display: inline-block;
  height: 32px; }

@media (min-width: 992px) {
  .QuestionnaireEditor .main-row {
    display: flex;
    flex-wrap: wrap; } }

.QuestionnaireEditor .config-opener {
  display: block;
  margin: 10px 0; }

.QuestionnaireEditor .general-config-wrapper {
  overflow: hidden;
  transition: max-height 350ms; }

@media (min-width: 992px) {
  .QuestionnaireEditor .options-card {
    position: sticky;
    top: 15px; } }

.QuestionnaireEditor .options-card button {
  display: block;
  width: 100%;
  text-align: left;
  margin: 10px 0;
  padding: 8px 8px 8px 30px;
  border-radius: 5px;
  background: #f3f7fb none;
  position: relative;
  border: 1px solid white;
  outline: none; }
  .QuestionnaireEditor .options-card button:before {
    content: '+';
    position: absolute;
    left: 10px;
    top: 50%;
    line-height: 100%;
    vertical-align: middle;
    margin-top: -0.5em;
    color: #6161b7; }
  .QuestionnaireEditor .options-card button:hover {
    box-shadow: 1px 1px 5px 1px inset rgba(0, 0, 0, 0.3); }

.QuestionnaireEditor .questions-card input.questionnaire-name {
  font-size: 26px;
  height: 50px; }

.QuestionnaireEditor .questions-card hr {
  margin: 10px 0 25px 0; }

.question-wrapper {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin: 20px 0;
  background-color: #f5f8fc;
  position: relative;
  padding: 10px 10px 10px 50px; }
  .question-wrapper.inactive-question {
    background-color: #ffc8c8; }
  .question-wrapper .left-handle {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 10px; }
    .question-wrapper .left-handle span {
      font-size: 17px;
      font-weight: bold;
      color: darkgray; }
    .question-wrapper .left-handle .thrash-container {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 10px; }
      .question-wrapper .left-handle .thrash-container i {
        cursor: pointer; }
        .question-wrapper .left-handle .thrash-container i:hover {
          color: red; }
  .question-wrapper .question-type-subtitle {
    margin-top: 5px; }

.GeneralConfig {
  border-radius: 5px;
  border: 1px solid #e8e9e8;
  padding: 15px; }
  .GeneralConfig .first-tagger {
    z-index: 2;
    position: relative; }
  .GeneralConfig .second-tagger {
    z-index: 1;
    position: relative; }

.QuestionnaireView .lock-button {
  margin: 20px 20px 0 0; }

.FileQuestion button {
  background: none;
  border: 1px solid #e0e1e1;
  color: #27d2f1;
  font-size: 20px;
  display: inline-block;
  min-width: 30px;
  border-radius: 5px;
  margin: 0 7px; }

.FileQuestion .uploaded-image {
  max-width: 100%; }

.QuestionnaireStats .filter-title {
  margin: 0; }

.QuestionnaireStats span.active-filter {
  padding: 5px 15px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #d9d9da;
  border-radius: 5px;
  background-color: #ebf5ff; }

.QuestionnaireStats .use-filter-but {
  margin: 20px 0 0 15px; }

.ScrollableStats {
  height: 300px;
  overflow: auto;
  box-shadow: 0 0 20px 0 rgba(30, 3, 60, 0.04); }
  .ScrollableStats .text-element {
    border: 1px solid #e4e4e4;
    padding: 5px;
    border-radius: 5px; }
  .ScrollableStats .file-element {
    border: 1px solid #e4e4e4;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center; }
    .ScrollableStats .file-element p {
      text-align: left; }
    .ScrollableStats .file-element img {
      max-width: 100%;
      box-shadow: -3px 6px 10px 2px rgba(0, 0, 0, 0.25);
      margin: 14px 0; }

.HappinessLastWeek .happiness-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; }

.HappinessLastWeek .lounge-container {
  display: inline-block;
  text-align: center;
  min-width: 300px;
  padding: 10px; }
  .HappinessLastWeek .lounge-container .inner-container {
    box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    padding: 10px; }
  .HappinessLastWeek .lounge-container img {
    width: 100px; }
  .HappinessLastWeek .lounge-container .score {
    font-size: 40px;
    font-weight: bold; }
    .HappinessLastWeek .lounge-container .score table {
      width: 100%; }
      .HappinessLastWeek .lounge-container .score table i.fa {
        width: initial;
        font-size: 28px;
        position: relative;
        top: -3px; }
        .HappinessLastWeek .lounge-container .score table i.fa.check-in {
          color: #35cf35; }
        .HappinessLastWeek .lounge-container .score table i.fa.check-out {
          color: #fc4b4b; }
    .HappinessLastWeek .lounge-container .score.check-types i.fa {
      font-size: 15px; }
  .HappinessLastWeek .lounge-container .shifts .score {
    font-size: 20px; }

.HappinessOverTimeGraph .chart-container {
  position: relative;
  overflow: auto; }
  .HappinessOverTimeGraph .chart-container .echarts-for-react {
    min-width: 750px; }
  .HappinessOverTimeGraph .chart-container .happiness-face {
    position: absolute;
    max-width: 35px;
    left: 2%; }
  .HappinessOverTimeGraph .chart-container .scale1 {
    bottom: 13%; }
  .HappinessOverTimeGraph .chart-container .scale2 {
    bottom: 26%; }
  .HappinessOverTimeGraph .chart-container .scale3 {
    bottom: 39%; }
  .HappinessOverTimeGraph .chart-container .scale4 {
    bottom: 52%; }
  .HappinessOverTimeGraph .chart-container .scale5 {
    bottom: 65%; }

.HappinessOverTimeGraph .rdt {
  max-width: 120px;
  display: inline-block;
  margin: 0 10px 10px 0; }

.HappinessOverTimeGraph .intervals-wrapper {
  margin-top: 10px; }
  .HappinessOverTimeGraph .intervals-wrapper button {
    background: none;
    box-shadow: 2px 3px 9px 0 rgba(0, 0, 0, 0.17);
    padding: 1px 10px; }
    .HappinessOverTimeGraph .intervals-wrapper button.left {
      border-radius: 3px 0 0 3px; }
    .HappinessOverTimeGraph .intervals-wrapper button.right {
      border-radius: 0 3px 3px 0; }
  .HappinessOverTimeGraph .intervals-wrapper span.number-picker {
    border: 1px #e7e7e7 solid;
    padding: 4px 6px;
    position: relative;
    top: 2px;
    background-color: white;
    box-shadow: inset -4px -4px 7px 0px rgba(0, 0, 0, 0.17);
    min-width: 63px;
    display: inline-block;
    text-align: center;
    max-height: 1.85em; }

.LastHappinessLogs .employee-link {
  cursor: pointer; }
  .LastHappinessLogs .employee-link:hover {
    text-decoration: underline; }

.AdvancedQuestionConfigurator .configuration-box {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e1e1;
  margin-bottom: 5px; }

.AdvancedQuestionConfigurator .ConditionalQuestion p {
  font-size: 14px; }

.AdvancedQuestionConfigurator .ConditionalQuestion select {
  margin-bottom: 10px; }

.ReservationsList .configuration {
  padding: 0 10px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-top: 20px; }
  .ReservationsList .configuration .filter-wrapper {
    padding: 15px; }
  .ReservationsList .configuration .filter-block {
    background-color: #f6f6f6;
    padding: 5px 5px 0;
    border-radius: 5px; }

.LayoffRequests .layoff-request-description {
  text-align: center;
  margin: 15px 0; }

.ProfileCompletionModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto; }
  .ProfileCompletionModal .content-wrapper {
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    box-shadow: 1px 1px 1px black;
    background-color: white; }
  .ProfileCompletionModal p {
    margin: 10px 0; }
  .ProfileCompletionModal .send-but {
    margin: 20px 0;
    float: right; }
  .ProfileCompletionModal .option-answer {
    padding: 5px 8px;
    display: inline-block;
    border: 1px solid #e3e3e3;
    margin: 5px;
    border-radius: 4px;
    background-color: #efefef; }

.PurchaseRequisitionForm .main-date, .PurchaseRequisitionForm .table-title {
  margin: 0; }

.PurchaseRequisitionForm .product-row .remove-row {
  color: red;
  cursor: pointer; }

.PurchaseRequisitionForm .main-date {
  float: right; }

.PurchaseRequisitionForm .stock-request-block {
  float: right;
  width: 300px; }

@media only screen and (min-width: 800px) {
  .PurchaseRequisitionForm .product-row .remove-row {
    visibility: hidden; }
  .PurchaseRequisitionForm .product-row:hover .remove-row {
    visibility: visible; }
  .PurchaseRequisitionForm .add-product-row:hover {
    background-color: #e6e6e6;
    position: relative;
    top: 2px;
    transition: 200ms all;
    font-size: 1.1em;
    cursor: pointer; } }

.PurchaseRequisitionForm .bottom-container .urgent-container {
  float: left; }

.PurchaseRequisitionForm .bottom-container .urgent-label, .PurchaseRequisitionForm .bottom-container .bootstrap-switch {
  vertical-align: middle; }

.PurchaseRequisitionForm .bottom-container .load-provider-wrap {
  float: right; }
  .PurchaseRequisitionForm .bottom-container .load-provider-wrap .provider-input {
    display: inline-block;
    vertical-align: top;
    min-width: 300px; }
    .PurchaseRequisitionForm .bottom-container .load-provider-wrap .provider-input .Select-control {
      height: 38px;
      border-radius: 5px 0 0 5px; }
  .PurchaseRequisitionForm .bottom-container .load-provider-wrap .btn-danger {
    border-radius: 0 5px 5px 0; }

.PurchaseRequisitionForm .card .table tbody td:last-child, .PurchaseRequisitionForm .card .table thead th:last-child {
  padding: 12px 8px; }

@media only screen and (max-width: 600px) {
  .PurchaseRequisitionForm .main-date {
    font-size: 1.4em; }
  .PurchaseRequisitionForm .table-title {
    margin: 20px 0; }
  .PurchaseRequisitionForm .bottom-container .urgent-container, .PurchaseRequisitionForm .bottom-container .load-provider-wrap {
    margin: 20px 0 0;
    float: none;
    text-align: center; } }

.TaxManagerModal .fa-trash {
  color: red;
  cursor: pointer; }

.TaxManagerModal .fa-check {
  color: green;
  cursor: pointer; }

.PurchaseRequisitionDetail .bottom-btn.right-btn {
  float: right; }

@media only screen and (max-width: 600px) {
  .PurchaseRequisitionDetail .bottom-btn-wrapper {
    text-align: center; }
    .PurchaseRequisitionDetail .bottom-btn-wrapper .bottom-btn {
      margin: 10px 0;
      min-width: 80%; }
      .PurchaseRequisitionDetail .bottom-btn-wrapper .bottom-btn.right-btn {
        float: none; } }

.PurchaseOrderDetail .provider-name {
  font-size: 1.2em;
  display: inline-block;
  margin-bottom: 15px; }

.PurchaseOrderDetail .requisition-link {
  color: inherit;
  font-weight: inherit; }
  .PurchaseOrderDetail .requisition-link:hover {
    color: #337ab7;
    text-decoration: underline; }

.PurchaseOrderDetail .PurchaseProductsTable .edit-btn {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: #2c6cf6; }

.PurchaseOrderDetail .PurchaseProductsTable .save-adjustment-btn, .PurchaseOrderDetail .PurchaseProductsTable .cancel-adjustment-btn {
  background: none;
  border: none; }

.PurchaseOrderDetail .PurchaseProductsTable .save-adjustment-btn {
  color: #87CB16; }

.PurchaseOrderDetail .PurchaseProductsTable .cancel-adjustment-btn {
  color: #FB404B; }

.PurchaseOrderDetail .transaction-btn {
  margin-top: 90px; }
  .PurchaseOrderDetail .transaction-btn .transaction-input {
    border-radius: 5px 0 0 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 3px 6px;
    min-width: 270px; }
  .PurchaseOrderDetail .transaction-btn .save-btn {
    border-radius: 0 5px 5px 0;
    position: relative;
    top: 1px; }

.PurchaseOrderDetail .export-link {
  margin-left: 10px; }

.EmployeeStatistics .stat-number {
  font-weight: bold;
  font-size: 35px; }

.AddStockAdjustment h4 {
  margin-top: 10px; }

.AddStockMovement .card {
  margin-bottom: 15px;
  margin-top: 0; }

.AddStockMovement .warehouse-select .dropdown {
  width: 100%; }
  .AddStockMovement .warehouse-select .dropdown ul {
    width: 100%;
    text-align: center; }

.AddStockMovement #warehouses {
  width: 100%; }
  .AddStockMovement #warehouses button {
    width: 100%; }

.AddStockMovement .product-detail {
  margin-top: 15px; }
  .AddStockMovement .product-detail h4 {
    margin: 10px 0; }
  .AddStockMovement .product-detail .prod-description {
    font-size: 0.95em;
    margin-top: 10px;
    border: 1px solid #DDD;
    padding: 10px;
    border-radius: 5px; }

.AddStockMovement .remove-product-icon {
  border: 1px solid red;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: red;
  background-color: #ffeded; }

.AddStockMovement .quantity-col {
  min-width: 100px; }
  @media only screen and (max-width: 767px) {
    .AddStockMovement .quantity-col {
      min-width: initial; } }

.AddStockMovement .product-img {
  border-radius: 5px; }

.AddStockMovement .pending-movements-button {
  position: relative; }

.AddStockMovement .notification {
  position: absolute;
  background-color: #FB404B;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 18px;
  top: -10px;
  right: -5px; }

.ProductDetail {
  padding: 10px; }
  .ProductDetail .report-link {
    margin-top: 20px; }
  .ProductDetail .description-box {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 15px; }
  .ProductDetail .product-brand-unit {
    margin: 5px 0;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    font-size: 1.1em;
    position: relative; }
    .ProductDetail .product-brand-unit.inactive {
      background-color: #ffc8c8; }
    .ProductDetail .product-brand-unit .barcode {
      font-size: 0.8em;
      color: gray; }
    .ProductDetail .product-brand-unit .act-btn {
      opacity: 0;
      position: absolute;
      bottom: 3px;
      right: 3px;
      background-color: whitesmoke;
      border-radius: 4px;
      border: none;
      color: #87CB16; }
      .ProductDetail .product-brand-unit .act-btn.deactivate {
        color: red; }
    .ProductDetail .product-brand-unit:hover .act-btn {
      opacity: 1; }

.LayoffsReport .range-button {
  margin-bottom: 30px; }

.LayoffsReport .filter-title {
  text-align: center; }

.LayoffsReport .filter-list {
  border: 1px solid whitesmoke;
  padding: 10px 26px;
  border-radius: 5px; }
  .LayoffsReport .filter-list .filter-item {
    padding: 3px;
    margin: 10px 0;
    box-shadow: 0 0 6px #d9edff; }
    .LayoffsReport .filter-list .filter-item .fa-close {
      float: right;
      margin-top: 2px;
      cursor: pointer;
      color: red; }

.LayoffsReport .hidden-names-container {
  padding: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 20px; }

.LayoffsReport .hidden-name {
  display: inline-block;
  padding: 5px 15px;
  margin: 5px;
  border: 1px solid #d23c65;
  border-radius: 5px;
  background: #ffebef;
  color: #864141;
  cursor: pointer; }

.LayoffsReport .table-container {
  overflow: auto;
  max-height: 90vh; }

.LayoffsReport th, .LayoffsReport td {
  text-align: center; }

.LayoffsReport th {
  background-color: #efefef;
  border: 1px solid #c2c2c2 !important; }

.LayoffsReport tbody th {
  width: 50%; }

.LayoffsReport td.empty {
  color: lightgrey; }

.LayoffsStats hr {
  border: 1px solid #c2c2c2;
  margin: 40px 0; }

.ProductFormModal .FileViewer img {
  max-height: 350px; }

.ProductFormModal .brand-box {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  position: relative; }
  .ProductFormModal .brand-box hr {
    margin: 15px 100px; }
  .ProductFormModal .brand-box.add-brand, .ProductFormModal .brand-box .add-unit {
    text-align: center; }
  .ProductFormModal .brand-box .remove-brand {
    position: absolute;
    top: 0.5em;
    right: 1em;
    font-size: 2em; }
  .ProductFormModal .brand-box .remove-unit-cont {
    text-align: center;
    padding-top: 1.2em; }
    @media only screen and (min-width: 768px) {
      .ProductFormModal .brand-box .remove-unit-cont {
        position: relative; }
        .ProductFormModal .brand-box .remove-unit-cont .remove-unit {
          position: absolute;
          top: 3em; } }

.UnitBundleCreator .f-label {
  position: relative;
  top: 7px; }

.SysadminDash .switch-buts-cont .btn {
  margin: 0 20px; }

.SysadminDash .switch-in {
  max-width: 160px;
  display: inline-block; }

.MeasurementConversionTemplatesList .one-template .inner-template {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px; }
  .MeasurementConversionTemplatesList .one-template .inner-template .iconsContainer {
    text-align: center; }

.MeasurementConversionTemplatesList .one-template h5, .MeasurementConversionTemplatesList .one-template .icons {
  text-align: center; }

.MeasurementConversionTemplatesList .one-template .fa {
  cursor: pointer;
  margin: 0 8px; }

.MeasurementConversionTemplatesList .one-template .fa-pencil {
  color: blue; }

.MeasurementConversionTemplatesList .one-template .fa-trash {
  color: red; }

.MeasurementConversionTemplatesList .one-template ul {
  margin: 10px 0; }

.CrashView {
  background: blue;
  padding-top: 1px;
  height: 100vh;
  background: linear-gradient(151deg, #ce4cde -8%, #10109f 22%, #37ddc6 100%);
  overflow: auto; }
  .CrashView .center-wrapper {
    width: 80%;
    margin: 50px auto;
    background-color: white;
    padding: 50px;
    min-height: 80vh;
    box-shadow: 0 50px 60px -6px black; }
    .CrashView .center-wrapper .logo-alma {
      width: 100%;
      max-width: 500px; }
    .CrashView .center-wrapper h1 {
      font-weight: bold;
      margin-bottom: 80px;
      font-size: 90px;
      color: #5c5c5c; }
    .CrashView .center-wrapper .p1 {
      font-size: 28px; }
    .CrashView .center-wrapper .crash-img {
      width: 100%; }
    @media only screen and (max-width: 500px) {
      .CrashView .center-wrapper {
        padding: 10px; }
        .CrashView .center-wrapper h1 {
          font-size: 70px; } }

.PurchaseProductQuoteRow {
  border: 1px solid #e3e3e3;
  cursor: n-resize; }
  .PurchaseProductQuoteRow.first {
    cursor: auto; }
  .PurchaseProductQuoteRow:hover {
    background-color: #ceeaff; }
  .PurchaseProductQuoteRow.up {
    background-color: #ffebeb; }
  .PurchaseProductQuoteRow.down {
    background-color: #e3ffe2; }

.PurchaseProductQuoteRow.quotation-row, .PurchaseProductAddRowQuote.quotation-row, .PurchaseProductSpacerRow.quotation-row {
  box-shadow: inset 0 10px 8px -9px rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.3s; }
  .PurchaseProductQuoteRow.quotation-row td:first-child .outer-div, .PurchaseProductAddRowQuote.quotation-row td:first-child .outer-div, .PurchaseProductSpacerRow.quotation-row td:first-child .outer-div {
    min-width: 20px; }
  .PurchaseProductQuoteRow.quotation-row td, .PurchaseProductAddRowQuote.quotation-row td, .PurchaseProductSpacerRow.quotation-row td {
    padding: 0 8px 8px; }
  .PurchaseProductQuoteRow.quotation-row.last-row td, .PurchaseProductAddRowQuote.quotation-row.last-row td, .PurchaseProductSpacerRow.quotation-row.last-row td {
    padding: 0 8px 30px;
    cursor: pointer; }
    .PurchaseProductQuoteRow.quotation-row.last-row td .outer-div, .PurchaseProductAddRowQuote.quotation-row.last-row td .outer-div, .PurchaseProductSpacerRow.quotation-row.last-row td .outer-div {
      border-radius: 4px; }
  .PurchaseProductQuoteRow.quotation-row .outer-div, .PurchaseProductAddRowQuote.quotation-row .outer-div, .PurchaseProductSpacerRow.quotation-row .outer-div {
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height 0.3s; }
    .PurchaseProductQuoteRow.quotation-row .outer-div .inner-div, .PurchaseProductAddRowQuote.quotation-row .outer-div .inner-div, .PurchaseProductSpacerRow.quotation-row .outer-div .inner-div {
      width: 100%;
      position: absolute;
      bottom: 0; }
      .PurchaseProductQuoteRow.quotation-row .outer-div .inner-div .Select-control, .PurchaseProductQuoteRow.quotation-row .outer-div .inner-div .form-control, .PurchaseProductAddRowQuote.quotation-row .outer-div .inner-div .Select-control, .PurchaseProductAddRowQuote.quotation-row .outer-div .inner-div .form-control, .PurchaseProductSpacerRow.quotation-row .outer-div .inner-div .Select-control, .PurchaseProductSpacerRow.quotation-row .outer-div .inner-div .form-control {
        background-color: transparent; }
  .PurchaseProductQuoteRow.quotation-row.q-open, .PurchaseProductAddRowQuote.quotation-row.q-open, .PurchaseProductSpacerRow.quotation-row.q-open {
    box-shadow: inset 0 10px 8px -9px rgba(0, 0, 0, 0); }
    .PurchaseProductQuoteRow.quotation-row.q-open td, .PurchaseProductAddRowQuote.quotation-row.q-open td, .PurchaseProductSpacerRow.quotation-row.q-open td {
      padding: 12px 8px; }
    .PurchaseProductQuoteRow.quotation-row.q-open .outer-div, .PurchaseProductAddRowQuote.quotation-row.q-open .outer-div, .PurchaseProductSpacerRow.quotation-row.q-open .outer-div {
      height: 40px;
      overflow: visible; }

.PurchaseRequisitionList .urgent-row {
  background-color: #ff4b65 !important; }

#purchase-requisition-providers-tooltip ul, #purchase-requisition-products-tooltip ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.PurchaseRequisitionQuoter .force-open-box {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer; }

@media only screen and (max-width: 767px) {
  .PurchaseRequisitionQuoter .bottom-actions {
    width: 48%;
    margin: 5px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px 10px; } }

.CommentsFeed {
  padding: 15px; }
  .CommentsFeed h4 span {
    line-height: 1.9; }
  .CommentsFeed .card-title {
    margin-top: 0;
    border-bottom: 1px solid #eaeaeb;
    padding-bottom: 15px; }
  .CommentsFeed .chat {
    list-style: none;
    background: none;
    margin: 0;
    padding: 0 0 20px 0;
    margin-top: 10px;
    max-height: 230px;
    overflow-y: scroll; }
  .CommentsFeed .chat > li {
    padding: 0.5rem;
    overflow: hidden;
    display: flex;
    display: -webkit-flex; }
  .CommentsFeed .chat .avatar {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-top: 0px;
    padding-top: 0px; }
  .CommentsFeed .chat .avatar img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
  .CommentsFeed .chat .footer {
    padding: 0;
    text-align: right;
    font-size: 11px;
    color: #9A9A9A; }
  .CommentsFeed .chat h6 {
    display: inline-block;
    font-size: 11px;
    font-weight: 300; }
  .CommentsFeed .send-message {
    text-align: left; }
  .CommentsFeed .send-message .avatar {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-top: 7px; }
  .CommentsFeed .send-message .avatar img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
  .CommentsFeed .send-message .send-button {
    width: 75px;
    display: inline-block; }
  .CommentsFeed .send-message .file-button {
    width: 54px;
    display: inline-block; }
  .CommentsFeed::-webkit-input-placeholder {
    color: #9A9A9A; }
  .CommentsFeed .other .msg {
    order: 1;
    border: 2px solid #f5f5f5;
    border-radius: 16px;
    position: relative;
    margin-left: 22px;
    max-width: 75%;
    background-color: #f5f5f5; }
  .CommentsFeed .other .msg:before {
    content: "";
    position: absolute;
    top: 15px;
    left: -16px;
    height: 0px;
    border: 8px solid #f5f5f5;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .CommentsFeed .other .no-avatar {
    width: 40px;
    height: 40px;
    order: 2; }
  .CommentsFeed .self {
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-end; }
  .CommentsFeed .self .msg {
    order: 1;
    border: 1px solid #dcf8c6;
    border-radius: 8px;
    position: relative;
    margin-right: 22px;
    max-width: 75%;
    background-color: #dcf8c6;
    position: relative; }
    .CommentsFeed .self .msg .remove-message {
      color: darkred;
      text-align: right; }
      .CommentsFeed .self .msg .remove-message i {
        cursor: pointer; }
  .CommentsFeed .self .avatar {
    order: 2;
    position: relative;
    overflow: visible; }
  .CommentsFeed .self .avatar:after {
    content: "";
    position: absolute;
    display: inline-block;
    bottom: 19px;
    right: 48px;
    width: 0px;
    height: 0px;
    border: 8px solid #dcf8c6;
    border-right-color: transparent;
    border-top-color: transparent; }
  .CommentsFeed .self .no-avatar {
    width: 40px;
    height: 40px;
    order: 2; }
  .CommentsFeed .msg {
    background: white;
    min-width: 50px;
    padding: 3px 10px 0px 10px;
    border-radius: 2px; }
  .CommentsFeed .msg p {
    margin: 0 0 0.1rem 0;
    font-size: 15px;
    line-height: 1; }
  .CommentsFeed .msg .name {
    font-weight: 600; }
  .CommentsFeed .msg img {
    position: relative;
    display: block;
    width: 450px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
  .CommentsFeed input.textarea {
    display: inline-block;
    width: calc(100% - 135px);
    margin-top: 10px; }
  .CommentsFeed.submit-hidden input.textarea {
    width: calc(100% - 56px); }
  @media screen and (max-width: 800px) {
    .CommentsFeed .msg img {
      width: 300px; } }
  @media screen and (max-width: 550px) {
    .CommentsFeed .main-comments img {
      width: 200px; } }
  .CommentsFeed .files {
    text-align: left; }
    .CommentsFeed .files div {
      display: inline-block;
      margin-left: 10px; }
    .CommentsFeed .files .fa-times {
      cursor: pointer; }
  .CommentsFeed #file-input {
    display: none; }
  .CommentsFeed .fa-file-archive-o {
    cursor: pointer; }
  .CommentsFeed .app-file-container {
    position: relative;
    display: inline-block;
    margin: 5px 5px 5px 5px;
    color: #444444;
    border: 1px solid #444444; }
    .CommentsFeed .app-file-container:hover {
      background-color: #60c0ee;
      color: white;
      border-color: white; }
      .CommentsFeed .app-file-container:hover .download {
        color: white; }
    .CommentsFeed .app-file-container .app-file-thumb {
      cursor: pointer;
      display: block;
      float: left;
      width: 150px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      line-height: .8;
      padding: 10px 5px 10px 5px; }
      .CommentsFeed .app-file-container .app-file-thumb img {
        border-radius: 0;
        max-width: 50px;
        margin: auto; }
      .CommentsFeed .app-file-container .app-file-thumb .name {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .CommentsFeed .app-file-container .app-file-thumb .mime {
        font-size: 10px; }
    .CommentsFeed .app-file-container .download {
      color: inherit;
      position: absolute;
      top: 10px;
      right: 5px; }
  .CommentsFeed .btn-primary.btn-fill:hover, .CommentsFeed .btn-primary.btn-fill {
    background-color: #60c0ee;
    border-color: #60c0ee; }
  .CommentsFeed .notifications-toggle {
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    margin-right: 15px; }

.ReactTableDataRangeFilter .modal-body {
  text-align: center; }

.ReactTableDataRangeFilter button.btn {
  width: 100%;
  padding: 2px; }

.ReactTableDataRangeFilter .DateRangePicker {
  width: 100%;
  text-align: center; }

.PurchaseRequisitionTotalRow {
  font-size: 18px; }

.FileIcon {
  display: inline-block;
  vertical-align: top;
  word-break: break-word;
  margin: 15px;
  cursor: pointer;
  position: relative;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .FileIcon {
      margin: 2px; } }
  .FileIcon:hover {
    background-color: #d4d4d4;
    border-radius: 5px; }
  .FileIcon:hover p {
    text-decoration: underline; }
  .FileIcon .fa-folder {
    color: #2a87d0;
    font-size: 100px; }
  .FileIcon p {
    text-align: center; }
  .FileIcon .fa-trash, .FileIcon .fa-pencil {
    position: absolute;
    top: 4px;
    font-size: 24px;
    cursor: pointer;
    opacity: 0; }
  .FileIcon .fa-trash {
    color: #fd573b;
    right: 4px; }
  .FileIcon .fa-pencil {
    color: blue;
    left: 4px; }
  .FileIcon:hover .fa-trash, .FileIcon:hover .fa-pencil {
    opacity: 1; }
  .FileIcon i.fa-trash:hover, .FileIcon i.fa-pencil:hover {
    font-size: 28px; }

@media only screen and (max-width: 768px) {
  .FileRepository .files-container {
    text-align: center; } }

.FileRepository .card {
  padding: 20px; }

.FileRepository .card-title {
  margin: 0; }
  .FileRepository .card-title i {
    cursor: pointer; }
    .FileRepository .card-title i:hover {
      color: #FF0000; }

.FileRepository .action-but {
  padding: 3px 8px;
  background-color: #ecf1f3;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block; }

.FileRepository .action-but:hover {
  background-color: #d4d4d4; }

.FileRepository .action-but input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  display: initial;
  opacity: 0;
  cursor: pointer; }

.FileRepository .actions-container {
  margin: 15px; }

.FileRepository .files-container h5 {
  margin: 30px; }

.EmployeePositionPermissions {
  border-radius: 5px;
  border: 1px solid #e8e9e8;
  padding: 15px; }
  .EmployeePositionPermissions .first-tagger {
    z-index: 2;
    position: relative; }
  .EmployeePositionPermissions .second-tagger {
    z-index: 1;
    position: relative; }

.GCareLoader {
  max-width: 900px;
  margin: 10px auto;
  border: 1px solid gray;
  padding: 15px;
  border-radius: 10px; }
  .GCareLoader .background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/img/gl-negro.png") no-repeat;
    background-size: contain;
    opacity: 0.05;
    pointer-events: none; }
  .GCareLoader h1.title, .GCareLoader .intro {
    text-align: center; }
  .GCareLoader .panel, .GCareLoader .well {
    background: none; }
  .GCareLoader .Question.well {
    text-align: center; }

.FooterContainer {
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 10px #c0c0c0;
  text-align: right;
  width: 100%;
  height: 40px;
  display: inline-block;
  left: 0;
  z-index: 1; }
  .FooterContainer .chat-nav-button {
    position: absolute;
    border: none;
    color: black;
    background-color: whitesmoke;
    width: 30px;
    height: 100%;
    z-index: 2; }
  .FooterContainer .back {
    left: 0; }
  .FooterContainer .forward {
    right: 0;
    top: 0; }

.Footer {
  height: 40px;
  width: 100%;
  white-space: nowrap;
  top: 0;
  padding: 0 30px 0 30px; }

.SingleChatBar {
  width: 300px;
  font-size: 1.1em;
  margin: 0 1px;
  display: inline-block;
  text-align: left;
  background-color: white;
  position: relative;
  transition: top 0.3s;
  transition-timing-function: ease-in;
  vertical-align: top; }
  .SingleChatBar .top-bar {
    background-color: #1c5086;
    color: white;
    padding: 10px 0 0 4px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    height: 40px; }
    .SingleChatBar .top-bar .status-point {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: greenyellow;
      border-radius: 5px;
      margin: -2px 8px 0 5px; }
  .SingleChatBar .control-bar {
    background-color: whitesmoke;
    height: 40px; }
    .SingleChatBar .control-bar button {
      border: solid 3px;
      border-radius: 15px;
      background-color: #082635;
      font-size: 18px;
      padding: 5px;
      width: 100%;
      text-align: center; }
  .SingleChatBar .chat-container {
    height: 0;
    border: 1px solid #DDDDDD;
    display: none;
    background-color: white;
    overflow: hidden; }
  .SingleChatBar.active {
    top: -490px; }
    .SingleChatBar.active .chat-container {
      height: 450px;
      display: block; }
  .SingleChatBar.closed {
    -webkit-animation: bounce-top 0.9s both;
    animation: bounce-top 0.9s both; }

.bounce {
  -webkit-animation: bounce 1s infinite;
  -moz-animation-duration: 1s;
  -moz-animation-name: bounce;
  -moz-animation-iteration-count: infinite; }

@keyframes bounce {
  0%, 10%, 30%, 50%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(-30px); }
  40% {
    transform: translateY(-15px); } }

.jiggle {
  -webkit-animation: jiggle 0.5s infinite;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: jiggle;
  -moz-animation-iteration-count: infinite;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg); }

/* ----------------------------------------------
 * Generated by Animista on 2019-8-14 11:30:30
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1; } }

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1; } }

.Chat {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .Chat .messages-container {
    flex: 1;
    max-height: 408px;
    overflow: auto; }
    .Chat .messages-container::before {
      content: "";
      background: url("../img/gl-negro.png") center no-repeat;
      background-size: 50%;
      opacity: 0.2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0;
      pointer-events: none; }
    .Chat .messages-container .message {
      position: relative;
      z-index: 1;
      background-color: #1c5086;
      margin: 5px;
      border-radius: 5px;
      padding: 5px 5px 0;
      color: white;
      float: left;
      min-width: 200px; }
      .Chat .messages-container .message.self {
        float: right;
        background-color: #8adb0f; }
      .Chat .messages-container .message .time {
        font-size: 0.7em;
        text-align: right; }
  .Chat .writer-container {
    background-color: whitesmoke;
    padding: 6px;
    width: 100%;
    z-index: 1; }
    .Chat .writer-container .input {
      background: white;
      border: none;
      border-radius: 5px;
      width: 88%;
      color: #444444;
      display: inline-block;
      max-height: 130px;
      overflow-y: auto;
      padding: 4px;
      vertical-align: bottom; }
      .Chat .writer-container .input:focus {
        outline: none;
        box-shadow: 0 0 5px 0 #bababa inset; }
    .Chat .writer-container button {
      background: none;
      border: none;
      color: #1c4f85;
      width: 12%;
      padding: 3px 0; }

.GuestChatNotifier {
  position: fixed;
  bottom: 60px;
  right: 40px;
  font-size: 60px;
  background-color: #ff6363;
  width: 100px;
  height: 100px;
  text-align: center;
  z-index: 2;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  animation: shadow-pulse 1s infinite; }
  .GuestChatNotifier .fa {
    width: initial;
    display: inline-block;
    position: absolute;
    left: 22px;
    top: 18px; }
  .GuestChatNotifier span {
    position: absolute;
    color: #ff6363;
    font-size: 40px;
    top: 20px;
    left: 40px; }

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 99, 99, 0.5); }
  100% {
    box-shadow: 0 0 0 45px rgba(0, 0, 0, 0); } }

.POPaymentMethodSelector .payment-method-btn {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid white; }

.POPaymentMethodSelector .payment-way-btn {
  border-radius: 0 5px 5px 0; }

.QuestionnaireStatsFilters .g-care-stat {
  border: 1px solid #DDD;
  border-radius: 5px;
  padding: 15px; }
  .QuestionnaireStatsFilters .g-care-stat .desc {
    font-size: 1.2em; }
  .QuestionnaireStatsFilters .g-care-stat .data {
    font-weight: bold;
    font-size: 2em; }
  .QuestionnaireStatsFilters .g-care-stat .bootstrap-switch {
    margin-top: 8px; }

.NotificationsList .notifications-list-container {
  background-color: white;
  border-radius: 5px;
  margin-top: 10px; }
  .NotificationsList .notifications-list-container ul.list {
    list-style: none;
    padding: 15px 0 0 0; }
  .NotificationsList .notifications-list-container li.list-item {
    padding: 15px 10px 15px 30px;
    border-bottom: 1px solid #DDD;
    position: relative;
    border-radius: 5px;
    cursor: pointer; }
    .NotificationsList .notifications-list-container li.list-item .notification-icon {
      position: absolute;
      left: 4px;
      top: 20px; }
    .NotificationsList .notifications-list-container li.list-item p {
      margin: 0; }
    .NotificationsList .notifications-list-container li.list-item.unread {
      background-color: #EEE; }
      .NotificationsList .notifications-list-container li.list-item.unread i.notification-icon {
        color: #ff5a88; }
    .NotificationsList .notifications-list-container li.list-item:hover {
      background-color: #DDD; }
    .NotificationsList .notifications-list-container li.list-item .notification-time {
      margin-top: 4px;
      font-size: 0.95em;
      color: grey; }

.WarehousesList .warehouse-panel {
  text-align: center;
  border: 1px solid #DDD;
  position: relative; }
  .WarehousesList .warehouse-panel .action-icon {
    border: 1px solid #DDD;
    border-radius: 5px;
    color: #555555;
    display: inline-block;
    padding: 8px;
    cursor: pointer;
    margin: 0 4px 8px;
    min-width: 84px;
    background-color: transparent;
    position: relative; }
    .WarehousesList .warehouse-panel .action-icon .notification {
      position: absolute;
      background-color: #FB404B;
      text-align: center;
      border-radius: 10px;
      min-width: 18px;
      padding: 0 5px;
      height: 18px;
      font-size: 12px;
      color: #FFFFFF;
      font-weight: bold;
      line-height: 18px;
      top: -3px;
      right: -3px; }
    .WarehousesList .warehouse-panel .action-icon:hover {
      color: #ff5f5b; }
    .WarehousesList .warehouse-panel .action-icon i.fa {
      font-size: 22px;
      width: initial; }
    .WarehousesList .warehouse-panel .action-icon span {
      font-size: 0.9em; }
  .WarehousesList .warehouse-panel .delete-icon {
    opacity: 0;
    color: red;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #DDD;
    transition: opacity 0.2s; }
  .WarehousesList .warehouse-panel:hover .delete-icon {
    opacity: 1; }
  .WarehousesList .warehouse-panel .edit-icon {
    opacity: 0;
    color: blue;
    position: absolute;
    top: 10px;
    right: 45px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #DDD;
    transition: opacity 0.2s; }
  .WarehousesList .warehouse-panel:hover .edit-icon {
    opacity: 1; }

.InventoryList h2 {
  margin: 0 0 15px 0; }

.InventoryList .list-photo-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: auto; }
  .InventoryList .list-photo-container img {
    width: 100%; }

.InventoryModeFormModal .actual-inventory {
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 5px; }

.InventoryModeFormModal .amount-input-block input, .InventoryModeFormModal .amount-input-block .dropdown {
  display: inline-block; }

.InventoryModeFormModal .amount-input-block input {
  border-radius: 5px 0 0 5px;
  width: 65%; }

.InventoryModeFormModal .amount-input-block .dropdown {
  width: 35%;
  border-radius: 0 5px 5px 0;
  background-color: transparent;
  padding: 10px 2px; }

.InventoryModeFormModal .product-photo {
  max-width: 90%;
  max-height: 300px;
  display: inline-block; }

.InventoryModeFormModal .edit-photo-btn {
  position: relative; }
  .InventoryModeFormModal .edit-photo-btn input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }

.InventoryMode .search-wrapper {
  position: relative; }
  .InventoryMode .search-wrapper .clear-search {
    position: absolute;
    right: 15px;
    top: 7px;
    background-color: transparent;
    border: none; }

.InventoryMode .title .hide-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #eee;
  float: right; }

.InventoryMode .hide-panel {
  overflow: hidden;
  transition: max-height 1s, padding 1s;
  max-height: 800px; }
  .InventoryMode .hide-panel.is-hidden {
    padding: 0;
    max-height: 0; }

.XFile .open-modal:hover {
  text-decoration: underline;
  cursor: grab; }

.NotificationsConfiguration .system-notifications {
  margin-top: 30px; }
  .NotificationsConfiguration .system-notifications ul:after {
    content: '';
    opacity: 0;
    transition: all .3s; }
  .NotificationsConfiguration .system-notifications.loading ul {
    position: relative; }
    .NotificationsConfiguration .system-notifications.loading ul:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.05);
      width: 100%;
      height: 100%;
      opacity: 1; }

.NotificationsConfiguration h3 {
  margin-bottom: 0; }

.NotificationsConfiguration hr {
  margin-bottom: 0; }

.NotificationsConfiguration .notifications-list-container {
  padding: 30px 30px 0 30px;
  background-color: white;
  border-radius: 5px;
  margin-top: 10px; }
  .NotificationsConfiguration .notifications-list-container ul.list {
    list-style: none;
    padding: 15px 5px 5px 5px; }
  .NotificationsConfiguration .notifications-list-container li.list-item {
    padding: 15px 10px 15px 0px;
    border-bottom: 1px solid #DDD;
    position: relative;
    border-radius: 5px;
    cursor: pointer; }
    .NotificationsConfiguration .notifications-list-container li.list-item .description {
      display: inline-block;
      margin-left: 10px; }
    .NotificationsConfiguration .notifications-list-container li.list-item p {
      margin: 0; }
    .NotificationsConfiguration .notifications-list-container li.list-item:hover {
      background-color: #f2f2f2; }

.NotificationsConfiguration .mail-notification-toggle, .NotificationsConfiguration .push-notification-toggle {
  float: right;
  text-align: center;
  margin-right: 10px; }
  .NotificationsConfiguration .mail-notification-toggle i, .NotificationsConfiguration .push-notification-toggle i {
    display: block;
    line-height: 1.4;
    margin: auto; }

.ApprovalMatrixConfigurator .approval-card {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px; }
  .ApprovalMatrixConfigurator .approval-card .face {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #d3dcb1;
    background-size: cover;
    background-position: center; }
    .ApprovalMatrixConfigurator .approval-card .face.stock_request {
      background-color: #ffb4c1; }
  .ApprovalMatrixConfigurator .approval-card .header, .ApprovalMatrixConfigurator .approval-card .body {
    padding: 10px; }
  .ApprovalMatrixConfigurator .approval-card .header {
    background-color: #d3dcb1;
    font-size: 1.25em;
    position: relative;
    padding-right: 65px; }
    .ApprovalMatrixConfigurator .approval-card .header.stock_request {
      background-color: #ffb4c1; }
    .ApprovalMatrixConfigurator .approval-card .header div.face {
      vertical-align: middle;
      margin-right: 10px; }
    .ApprovalMatrixConfigurator .approval-card .header .edit-rule-btn, .ApprovalMatrixConfigurator .approval-card .header .remove-rule-btn {
      background: none;
      border: none;
      position: absolute;
      top: 10px; }
    .ApprovalMatrixConfigurator .approval-card .header .edit-rule-btn {
      color: #6ea1ff;
      right: 7px; }
    .ApprovalMatrixConfigurator .approval-card .header .remove-rule-btn {
      color: #ff7272;
      right: 37px; }
  .ApprovalMatrixConfigurator .approval-card .body {
    text-align: center; }
    .ApprovalMatrixConfigurator .approval-card .body .subordinate {
      width: 100px;
      display: inline-block;
      vertical-align: top;
      position: relative; }
      .ApprovalMatrixConfigurator .approval-card .body .subordinate .remove-sub-btn {
        position: absolute;
        top: 0;
        right: 15px;
        padding: 0;
        margin: 0;
        background: transparent;
        border: none; }
      @media only screen and (min-width: 600px) {
        .ApprovalMatrixConfigurator .approval-card .body .subordinate .remove-sub-btn {
          opacity: 0; }
        .ApprovalMatrixConfigurator .approval-card .body .subordinate:hover .remove-sub-btn {
          opacity: 1; } }
    .ApprovalMatrixConfigurator .approval-card .body .add-sub-btn {
      vertical-align: top;
      background-color: transparent;
      border: none;
      font-size: 3em;
      color: #BBB;
      outline: none;
      width: 100px; }
      .ApprovalMatrixConfigurator .approval-card .body .add-sub-btn i {
        width: 1em; }
    .ApprovalMatrixConfigurator .approval-card .body .priority-text {
      color: #AAA;
      margin: 0;
      font-size: 0.95em; }
  .ApprovalMatrixConfigurator .approval-card.add {
    padding: 10px;
    font-size: 1.5em; }
    .ApprovalMatrixConfigurator .approval-card.add .add-card-btn {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none; }
    .ApprovalMatrixConfigurator .approval-card.add i {
      margin-right: 1em;
      width: 1em; }

.EmployeeWorkToolCalendarModal .face {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #d3dcb1;
  background-size: cover;
  background-position: center;
  vertical-align: middle;
  margin-right: 10px; }

.EmployeeWorkToolCalendarModal .employee-row {
  margin-bottom: 10px; }
  .EmployeeWorkToolCalendarModal .employee-row .btn-success {
    float: right; }
  .EmployeeWorkToolCalendarModal .employee-row span {
    cursor: pointer; }

.WorkTool .panel:hover .work-tool-detail span i {
  visibility: visible;
  position: absolute; }

.WorkTool .work-tool-detail > div {
  position: relative; }

.WorkTool .work-tool-detail input.form-control {
  display: inline-block;
  height: 25px;
  width: 80px; }

.WorkTool .work-tool-detail span i {
  visibility: hidden;
  position: absolute; }

.WorkTool .price-edit-block {
  display: inline-block; }

.WorkTool button {
  background: none;
  border: 1px solid #e3e3e3; }
  .WorkTool button i.fa {
    visibility: visible; }
  .WorkTool button i.fa-save {
    color: #49d0ec; }
  .WorkTool button i.fa-times {
    color: red; }

.WorkToolFormModal p.wt-label {
  text-align: right; }

.EmployeeWorkToolsManager .filters-wrapper {
  padding: 15px;
  border: 1px solid #EEE;
  border-radius: 4px;
  margin-bottom: 15px; }

.InventoryMovementsHistory .pending-movement {
  background: rgba(253, 234, 149, 0.6) !important; }

.InventoryMovementsHistory .filters-wrapper {
  margin-bottom: 15px; }

.InventoryMovementsHistory .ReactTable .-totalPages {
  display: none; }

.Rewards.splash-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #4C4A9C 0%, #4B4999EA 0%, #6177C6F4 0%, #6178C7F4 55%, #78A8F6 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Rewards.splash-screen .logo {
    max-width: 266px; }

.Rewards .clickable {
  cursor: pointer; }

.Rewards .search-products {
  margin-top: 17.5px;
  margin-left: auto;
  margin-right: auto;
  width: 87%;
  max-width: 600px;
  display: flex;
  justify-content: space-between; }
  .Rewards .search-products .search-box {
    border: 2px solid white; }

.Rewards .search-employees {
  width: 85.6%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17.5px;
  display: flex;
  justify-content: space-between; }
  .Rewards .search-employees .search-box {
    border: 2px solid #FF8132; }

.Rewards .search-icon {
  position: absolute;
  height: 13.52px;
  margin: 5px; }

.Rewards .search-box {
  flex: 579;
  border-radius: 3px;
  background-color: transparent;
  height: 24px;
  padding-left: 20px;
  color: white; }

.Rewards .filter-button {
  flex: 73;
  display: flex;
  justify-content: flex-end; }
  .Rewards .filter-button button {
    border: none;
    background-color: transparent; }
    .Rewards .filter-button button img {
      height: 18.37px; }

.Rewards .employee-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .Rewards .employee-list .employee-row {
    background: rgba(248, 249, 253, 0.07) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85.6%;
    max-width: 400px;
    padding: 7.5px 21px 7.5px 13.3px;
    position: relative; }
    .Rewards .employee-list .employee-row p {
      font-size: 10px;
      letter-spacing: 0; }
    .Rewards .employee-list .employee-row .actions-menu {
      position: absolute;
      right: 10px;
      bottom: calc(50% - 55px);
      background-color: rgba(255, 255, 255, 0.7);
      color: black;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px; }
    .Rewards .employee-list .employee-row .data-container {
      width: 42%;
      margin-left: -12.5%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .Rewards .employee-list .employee-row .data-container p {
        margin-bottom: 0; }
      .Rewards .employee-list .employee-row .data-container .employee-name {
        font-size: 12px;
        letter-spacing: 0; }
    .Rewards .employee-list .employee-row .picture-container {
      width: 17.8%;
      position: relative; }
      .Rewards .employee-list .employee-row .picture-container .picture {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 50%; }
    .Rewards .employee-list .employee-row .picture-container::after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .Rewards .employee-list .employee-row .actions {
      width: 12%;
      display: flex;
      height: 50%; }
      .Rewards .employee-list .employee-row .actions button {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: transparent;
        border: none; }
        .Rewards .employee-list .employee-row .actions button .dot {
          width: 4px;
          height: 4px;
          background: #070606 0% 0% no-repeat padding-box;
          border-radius: 50%;
          /*
            margin-left: 1px;
            margin-right: 1px;
            */
          margin: auto 1px; }

.Rewards .main {
  overflow: hidden;
  height: 10%; }

.nav-open .system-rewards {
  background-color: black; }
  .nav-open .system-rewards .main-panel {
    opacity: 0.3; }

.system-rewards {
  position: relative;
  color: white;
  /* Weird white line
  .sidebar {
      width: 261px;
      .sidebar-wrapper {
          width: 262px;
      }
  }
  */ }
  .system-rewards input {
    color: black; }
  .system-rewards select {
    color: black; }
  .system-rewards .ReactTable.-striped .rt-thead .rt-resizable-header-content {
    background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box; }
  .system-rewards .ReactTable.-striped .rt-tbody {
    border-top: white solid 1px; }
  .system-rewards .ReactTable.-striped .rt-tr.-odd {
    background-color: rgba(100, 100, 100, 0.4); }
  .system-rewards .ReactTable.-striped .-pagination .-btn {
    color: white; }
  .system-rewards .ReactTable.-striped .has-error input {
    color: #FB404B;
    border-color: #FB404B; }
  .system-rewards .ReactTable.-striped input {
    background-color: #040826;
    border-color: white;
    color: white; }
  .system-rewards .btn {
    border-color: white;
    color: white; }
  .system-rewards .btn-info {
    border-color: #943bea;
    color: #943bea; }

@keyframes slideup {
  from {
    bottom: -200px; }
  to {
    bottom: 0; } }
  .system-rewards .collapsable-content from {
    transform: translateY(-40px); }
  .system-rewards .collapsable-content to {
    transform: translateY(0); }
  .system-rewards .Search.Filter {
    animation-duration: 0.3s;
    animation-name: slideup;
    color: white;
    position: absolute;
    bottom: 0;
    background: transparent linear-gradient(293deg, rgba(250, 245, 246, 0.28) 0%, #FCFAFA60 60%, rgba(253, 253, 253, 0.28) 100%) 0% 0% no-repeat padding-box;
    border-radius: 22.5px 22.5px 0 0;
    width: 100%;
    max-width: 500px;
    z-index: 11;
    text-align: center;
    left: calc(50% - 250px);
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .system-rewards .Search.Filter .content {
      margin-bottom: 40px; }
    .system-rewards .Search.Filter .filter-title {
      font-weight: bold; }
    .system-rewards .Search.Filter .options-container {
      width: 100%; }
    .system-rewards .Search.Filter .filter-option {
      width: 85%;
      padding: 12.5px 5px;
      margin: auto auto;
      cursor: pointer; }
    .system-rewards .Search.Filter .picked {
      background-color: rgba(255, 255, 255, 0.24); }
    .system-rewards .Search.Filter .handle-container {
      margin-top: 13.5px;
      margin-bottom: 15px;
      width: 100%;
      height: 4.5px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .system-rewards .Search.Filter .handle-container .handle {
        width: 15.6%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.17);
        border-radius: 7.5px; }
  .system-rewards .main-panel {
    background-color: #040826; }
  .system-rewards h1, .system-rewards .h1, .system-rewards h2, .system-rewards .h2, .system-rewards h3, .system-rewards .h3, .system-rewards h4, .system-rewards .h4, .system-rewards h5, .system-rewards .h5, .system-rewards h6, .system-rewards .h6, .system-rewards p, .system-rewards .navbar, .system-rewards .brand, .system-rewards .btn-simple, .system-rewards .alert, .system-rewards a, .system-rewards .td-name, .system-rewards td, .system-rewards button.close {
    font-family: Roboto; }
  .system-rewards .sidebar .sidebar-wrapper {
    background-color: #FD3851; }
  .system-rewards .logo {
    background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box; }
  .system-rewards .navbar.blurred {
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
    pointer-events: none;
    animation-duration: 1s;
    animation-name: dissolve; }
  .system-rewards .navbar {
    background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box;
    height: 67.38px;
    align-items: center;
    justify-content: center;
    z-index: 1; }
    .system-rewards .navbar .container-fluid {
      height: 100%; }
    .system-rewards .navbar .navbar-header {
      font: Bold 24px Roboto;
      height: 100%;
      display: flex;
      flex-direction: row;
      color: white; }
      .system-rewards .navbar .navbar-header img {
        width: 40px; }
      .system-rewards .navbar .navbar-header .header-button {
        flex: 1;
        cursor: pointer; }
      .system-rewards .navbar .navbar-header .back-icon {
        flex: 1;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%; }
      .system-rewards .navbar .navbar-header .navbar-brand {
        flex: 4;
        color: white;
        letter-spacing: 0;
        font-weight: bold;
        line-height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
      .system-rewards .navbar .navbar-header .right-icon {
        flex: 1;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%; }
        .system-rewards .navbar .navbar-header .right-icon .icon-bar {
          top: 57px;
          left: 664px;
          width: 18px;
          height: 5.4px;
          border-top: white solid 1px; }
  .system-rewards .navbar-default {
    border: none;
    position: sticky;
    top: 0; }
  .system-rewards .navbar-toggle .icon-bar {
    background-color: white; }
  .system-rewards .footer-menu.blurred {
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
    pointer-events: none;
    animation-duration: 1s;
    animation-name: dissolve; }
  .system-rewards .footer-container {
    position: sticky;
    margin: 0 auto;
    bottom: 17.76px;
    height: 41px;
    width: 93.6%; }
  .system-rewards .footer-menu {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    max-width: 500px;
    z-index: 10;
    border-radius: 21px;
    background-color: #0F1330;
    margin: auto; }
    .system-rewards .footer-menu .footer-button {
      height: 18.04px;
      width: 18.04px;
      border: none;
      margin: 0;
      background-color: transparent; }
      .system-rewards .footer-menu .footer-button img {
        height: 100%; }
    .system-rewards .footer-menu .footer-button.selected {
      background-color: #FD3851;
      margin-bottom: 10.5px;
      border-radius: 50%;
      border: #0F1330 solid 5px;
      height: 39.36px;
      width: 39.36px;
      animation-duration: 1s;
      animation-name: grow; }
      .system-rewards .footer-menu .footer-button.selected img {
        height: 50%; }
  .system-rewards .main-panel {
    padding-bottom: 0px; }

@keyframes grow {
  from {
    width: 0;
    height: 0; }
  to {
    height: 39.36px;
    width: 39.36px; } }

.Rewards {
  background-color: #040826;
  padding-bottom: 50px;
  height: calc(100% - 67.38px);
  min-height: calc(100vh - 67.38px); }
  .Rewards .bold {
    font-weight: bold; }
  .Rewards .underline {
    font-weight: bold;
    text-decoration: underline; }

.RewardsModal .modal-header {
  background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box; }

.RewardsModal img {
  width: 100%; }

.Rewards.blurred {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
  animation-duration: 1s;
  animation-name: dissolve;
  pointer-events: none; }

@keyframes dissolve {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media only screen and (max-width: 500px) {
  .system-rewards .Search.Filter {
    left: 0; } }

.overlay.blurred {
  height: 100vh;
  width: 100vw;
  top: 0;
  position: absolute;
  background-color: transparent; }

.GiveAwardToEmployee .message-box {
  background: transparent url("../../assets/img/tgle-rewards/message-bubble.svg") 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .GiveAwardToEmployee .message-box textarea {
    background: transparent;
    border: none;
    width: 650px; }

.Rewards.ChooseAwardForEmployee {
  color: white;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto; }
  .Rewards.ChooseAwardForEmployee .error-message {
    background-color: #FE625E;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 5px;
    border-radius: 5px;
    animation-name: discover;
    animation-duration: 3s;
    height: 35px; }

@keyframes discover {
  from-padding: 0px;
  to-padding: 5px; }
  .Rewards.ChooseAwardForEmployee .points-badge {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 1.5px solid #FE625E;
    position: absolute;
    top: 0;
    right: 15%;
    background-color: #23242A;
    padding: auto; }
    .Rewards.ChooseAwardForEmployee .points-badge p {
      height: 100%;
      padding: 12.5%;
      font-size: 8px; }
  .Rewards.ChooseAwardForEmployee .medal-list {
    margin: 0 auto 0 auto;
    width: 86%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .Rewards.ChooseAwardForEmployee .medal-item.disabled {
    cursor: not-allowed; }
    .Rewards.ChooseAwardForEmployee .medal-item.disabled .image-container {
      background-color: rgba(125, 125, 125, 0.04); }
    .Rewards.ChooseAwardForEmployee .medal-item.disabled .medal-badge {
      border: 0.5px solid #A5A5A5;
      color: #545454; }
  .Rewards.ChooseAwardForEmployee .medal-item {
    cursor: pointer;
    margin-top: 31.75px;
    width: 33%;
    max-width: 100px;
    box-sizing: border-box; }
    .Rewards.ChooseAwardForEmployee .medal-item .medal-details {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center; }
    .Rewards.ChooseAwardForEmployee .medal-item .image-container {
      background-color: rgba(255, 255, 255, 0.04);
      border-radius: 50%;
      display: inline-block;
      padding: 20px; }
      .Rewards.ChooseAwardForEmployee .medal-item .image-container img {
        width: 33.5px; }
    .Rewards.ChooseAwardForEmployee .medal-item .medal-badge {
      border: 1px solid #FE625E;
      border-radius: 25px;
      display: inline-block;
      padding: 2.5px 12.5px;
      margin-top: 8px;
      font-size: 10px; }
  .Rewards.ChooseAwardForEmployee .medal-item.picked .medal-badge {
    border: 2px solid #FD3851;
    border-radius: 25px;
    display: inline-block;
    padding: 2.5px 12.5px;
    margin-top: 8px; }
  .Rewards.ChooseAwardForEmployee .title-text {
    margin-top: 23px;
    margin-left: 35px; }
  .Rewards.ChooseAwardForEmployee .info-box {
    background-color: #2A3069;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    padding: 30px;
    box-sizing: border-box; }

.ChooseAwardForEmployee .bold {
  font: Bold 12px Roboto; }

.ChooseAwardForEmployee .message-bubble {
  margin: 0 auto;
  color: black;
  background-color: #6BE7CF;
  z-index: 15;
  border-radius: 22.5px;
  width: 80%;
  height: 119px;
  padding: 20.5px 20px; }
  .ChooseAwardForEmployee .message-bubble p {
    font-size: 11px; }
  .ChooseAwardForEmployee .message-bubble textarea {
    background-color: transparent;
    width: 90%;
    margin: 0 auto;
    border: none; }

.ChooseAwardForEmployee .answer-bubble {
  z-index: 15;
  width: 80%;
  color: white;
  background-color: #2A3069;
  border-radius: 17.5px;
  margin: 18.21px auto 0 auto;
  height: 62px;
  display: flex; }
  .ChooseAwardForEmployee .answer-bubble p {
    margin: auto 19px auto 19px;
    font-size: 11px; }
  .ChooseAwardForEmployee .answer-bubble .bold {
    font-weight: bold; }

.ChooseAwardForEmployee .action-buttons {
  width: 80%;
  margin: 15px auto;
  display: flex;
  justify-content: space-between; }
  .ChooseAwardForEmployee .action-buttons button {
    width: 200px;
    color: black;
    padding: 11px 30px;
    border: none;
    border-radius: 10px;
    margin: 5px; }
  .ChooseAwardForEmployee .action-buttons .ok-button {
    background-color: #6BE7CF; }
  .ChooseAwardForEmployee .action-buttons .cancel-button {
    background-color: #FE625E; }

.ChooseAwardForEmployee .overlay-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px; }

.Rewards.ChooseAwardForEmployee.blurred {
  max-height: 100vh; }

.ChooseAwardForEmployee.overlay {
  position: absolute;
  width: 100%;
  height: 114px;
  top: 217px;
  z-index: 15; }

.Rewards.store {
  color: white;
  background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box; }
  .Rewards.store .main-container {
    background: transparent linear-gradient(270deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box;
    width: 100%; }
  .Rewards.store .stats-container {
    scrollbar-width: none;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto; }
    .Rewards.store .stats-container::-webkit-scrollbar {
      display: none; }
    .Rewards.store .stats-container .stat-container {
      display: inline-block;
      text-align: center; }
      .Rewards.store .stats-container .stat-container p {
        margin-top: 7.5px;
        text-align: center;
        letter-spacing: 0;
        font: Bold 10.1px Roboto; }
      .Rewards.store .stats-container .stat-container h3 {
        font-size: 15.5px;
        font-weight: bold;
        margin-top: 36.5px; }
    .Rewards.store .stats-container .stat-container.selected h3 {
      font: Bold 25.2px Roboto; }
  .Rewards.store .page-indicator-row {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px; }
    .Rewards.store .page-indicator-row .page-indicator {
      display: flex;
      justify-content: space-between;
      width: 33.5px; }
      .Rewards.store .page-indicator-row .page-indicator .indicator-dot {
        width: 7.5px;
        height: 7.5px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.35); }
    .Rewards.store .page-indicator-row .indicator-dot.selected {
      background-color: white; }
  .Rewards.store .products-stats-box.selected h3 {
    font: Bold 25.2px Roboto; }
  .Rewards.store .products {
    background: #040826 0% 0% no-repeat padding-box;
    border-radius: 22.5px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 280px);
    max-width: 700px;
    margin: 0 auto; }
    .Rewards.store .products h4 {
      font-size: 14px;
      margin-top: 16.5px; }
    .Rewards.store .products .store-content {
      margin-left: 29.4px;
      margin-right: 29.4px; }
    .Rewards.store .products .handle-container {
      width: 100%;
      margin-top: 13.5px;
      height: 4.5px; }
      .Rewards.store .products .handle-container .handle {
        margin-left: 42%;
        margin-right: 42%;
        height: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 7.5px;
        opacity: 0.17; }
    .Rewards.store .products .product-container {
      width: 47.6%;
      max-width: 200px; }
      .Rewards.store .products .product-container .image-container {
        background: #1B1D38 0% 0% no-repeat padding-box;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        cursor: pointer; }
        .Rewards.store .products .product-container .image-container img {
          height: 100%; }
      .Rewards.store .products .product-container .product-info {
        display: flex;
        justify-content: space-between; }
        .Rewards.store .products .product-container .product-info p {
          font: Normal 10px Roboto; }
    @media only screen and (max-width: 330px) {
      .Rewards.store .products .product-container {
        width: 100%; }
        .Rewards.store .products .product-container .image-container {
          height: 150px; } }
    .Rewards.store .products .products-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; }
  .Rewards.store .wish-button {
    border: none;
    background-color: transparent; }
    .Rewards.store .wish-button img {
      width: 66%; }

.Rewards.store.blurred {
  max-height: calc(100vh - 67.38px - 17.5px);
  overflow: hidden; }

.FeedItem {
  background: transparent linear-gradient(138deg, rgba(255, 255, 255, 0.21) 0%, rgba(128, 128, 128, 0.21) 100%) 0% 0% no-repeat padding-box;
  margin: 25.5px 0 10px 0;
  padding-bottom: 12.5px;
  padding-top: 13.7px;
  border-radius: 10px 10px 55px 10px;
  color: #FFFFFF;
  font-size: 11.5px; }
  .FeedItem p {
    font-size: 13.5px; }
  .FeedItem p, .FeedItem h1, .FeedItem h2, .FeedItem h3, .FeedItem h4, .FeedItem h5, .FeedItem h6 {
    margin-bottom: 0;
    margin-top: 0; }
  .FeedItem hr {
    width: 100%;
    border: 0.5px solid #FFFFFF;
    opacity: 0.21; }
  .FeedItem .feed-item-container {
    margin: 20px 24.7px 20px 24.7px;
    display: flex;
    flex-direction: column; }
  .FeedItem .feed-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 19px; }
  .FeedItem .bold {
    font-weight: bold; }
  .FeedItem .employee-image {
    display: flex;
    align-items: center;
    width: 10%; }
    .FeedItem .employee-image img {
      max-width: 100%;
      height: auto;
      border-radius: 50%; }
  .FeedItem .medal-image {
    display: flex;
    align-items: center;
    width: 10%; }
    .FeedItem .medal-image img {
      max-width: 100%;
      height: auto; }
  .FeedItem .employee-header {
    height: 100%;
    width: 80%;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .FeedItem .employee-header p {
      font-size: 12px; }
    .FeedItem .employee-header h4 {
      font-size: 13px;
      line-height: 1; }

.Rewards.RewardsFeed {
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }
  .Rewards.RewardsFeed .refresh-feed-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 29.25px; }
    .Rewards.RewardsFeed .refresh-feed-container .refresh-feed-button {
      background-color: #6BE7CF;
      padding: 3.25px 7.15px 3.25px 7.15px;
      border-radius: 12px;
      border: none;
      height: 24px;
      letter-spacing: 0;
      color: #040826; }
      .Rewards.RewardsFeed .refresh-feed-container .refresh-feed-button p {
        margin: auto auto;
        font-size: 12px; }
  .Rewards.RewardsFeed .feed-top-menu {
    color: white;
    margin-top: 15px;
    list-style-type: none; }
    .Rewards.RewardsFeed .feed-top-menu li {
      display: inline; }
    .Rewards.RewardsFeed .feed-top-menu a {
      color: white;
      text-decoration: underline; }

.Rewards.rewards-give-award .picked {
  background-color: #6BE7CF;
  color: #040826; }

.Rewards.rewards-give-award .top-message {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  background-color: #2A3069;
  padding: 30px;
  text-align: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  font-weight: bold;
  animation-name: fromtop;
  animation-duration: 1s; }

@keyframes fromtop {
  from {
    margin-top: -50px; }
  to {
    margin-top: 0; } }

.Rewards.rewards-give-award.blurred {
  max-height: calc(100vh - 67.38px - 17.5px);
  overflow: hidden; }

.Rewards.Activity {
  color: white;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }
  .Rewards.Activity .info-text {
    margin-top: 20px;
    text-align: center; }
  .Rewards.Activity .activity-group {
    color: #F8F8F5;
    margin: 33px 33px 0 33px; }
    .Rewards.Activity .activity-group .activity-group-name {
      font-size: 15px;
      letter-spacing: 0; }
    .Rewards.Activity .activity-group .activity-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin-top: 13.5px; }
      .Rewards.Activity .activity-group .activity-row .activity-picture {
        width: 12%;
        position: relative; }
        .Rewards.Activity .activity-group .activity-row .activity-picture .picture {
          position: absolute;
          top: 0;
          bottom: 0;
          border-radius: 50%;
          height: 100%;
          width: 100%;
          background-position: center;
          background-size: cover; }
      .Rewards.Activity .activity-group .activity-row .activity-picture::after {
        content: "";
        display: block;
        padding-bottom: 100%; }
      .Rewards.Activity .activity-group .activity-row .activity-label {
        width: 80%;
        background-color: rgba(255, 255, 255, 0.07);
        border-radius: 10px 10px 20px 10px;
        padding: 10.41px 10.5px 10.7px 10.4px;
        min-height: 70px; }
        .Rewards.Activity .activity-group .activity-row .activity-label p {
          font-size: 10px;
          margin: auto auto; }
        .Rewards.Activity .activity-group .activity-row .activity-label .link {
          color: #FD3851;
          text-decoration: underline;
          font: Normal 11px Roboto;
          letter-spacing: 0; }
        .Rewards.Activity .activity-group .activity-row .activity-label .info {
          color: #71716F;
          font: Normal 11px Roboto;
          letter-spacing: 0; }
      @media only screen and (max-width: 390px) {
        .Rewards.Activity .activity-group .activity-row .activity-picture {
          width: 20%; }
        .Rewards.Activity .activity-group .activity-row .activity-label {
          width: 65%; } }
  .Rewards.Activity .recent .activity-row .activity-label {
    background-color: rgba(187, 89, 236, 0.25); }

.Rewards.Activity.recognition-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  max-width: none;
  height: 100vh;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font: Light 26px/36px Roboto;
  padding-bottom: 0; }
  .Rewards.Activity.recognition-overlay .close-button {
    position: fixed;
    top: 36.5px;
    right: 29.5px;
    font-size: 20px;
    z-index: 1;
    cursor: pointer; }
  .Rewards.Activity.recognition-overlay .overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 71.3%;
    height: 100vh;
    text-align: center;
    justify-content: center;
    max-width: 420px;
    z-index: 1; }
  .Rewards.Activity.recognition-overlay .red-info {
    color: #FD3851; }
  .Rewards.Activity.recognition-overlay .image-container {
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 50%;
    display: inline-block;
    padding: 27.5px; }
    .Rewards.Activity.recognition-overlay .image-container img {
      height: 56.5px; }
  .Rewards.Activity.recognition-overlay .medal-item {
    width: 50%; }
  .Rewards.Activity.recognition-overlay .medal-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .Rewards.Activity.recognition-overlay .medal-badge {
    border: 3px solid #6BE7CF;
    border-radius: 25px;
    display: inline-block;
    padding: 2.5px 12.5px;
    margin-top: 8px; }
  .Rewards.Activity.recognition-overlay .points-badge {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1.5px solid #6BE7CF;
    position: absolute;
    top: 0;
    right: 15%;
    background-color: #23242A;
    padding: auto; }
    .Rewards.Activity.recognition-overlay .points-badge p {
      height: 100%;
      padding: 12.5%; }
  .Rewards.Activity.recognition-overlay .medal-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Rewards.Activity.recognition-overlay .message {
    margin-top: 15.5px; }

.Rewards.ProductDescription {
  color: white;
  background-color: #2A3069; }
  .Rewards.ProductDescription .more-products {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
    height: 150px; }
    .Rewards.ProductDescription .more-products .product-container {
      width: 150px;
      flex-shrink: 0;
      margin: 15px; }
      .Rewards.ProductDescription .more-products .product-container .image-container {
        background: #1B1D38 0% 0% no-repeat padding-box;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        cursor: pointer; }
        .Rewards.ProductDescription .more-products .product-container .image-container img {
          height: 100%; }
      .Rewards.ProductDescription .more-products .product-container .product-info {
        display: flex;
        justify-content: space-between; }
        .Rewards.ProductDescription .more-products .product-container .product-info p {
          font: Normal 10px Roboto; }
    .Rewards.ProductDescription .more-products .wish-button {
      display: none; }
  .Rewards.ProductDescription .product-image-container {
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Rewards.ProductDescription .product-image-container img {
      height: 75%; }
  .Rewards.ProductDescription .value-text {
    color: #FD3851;
    font: Normal 15px Roboto; }
  .Rewards.ProductDescription .product-info {
    border-top-right-radius: 40px;
    background-color: #040826;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 75vh; }
  .Rewards.ProductDescription .product-label {
    display: flex;
    align-items: center; }
    .Rewards.ProductDescription .product-label .product-label-container {
      margin: 15px 43.5px 15px 26.5px;
      flex: 2; }
      .Rewards.ProductDescription .product-label .product-label-container p {
        font: Normal 15px Roboto;
        letter-spacing: 0; }
      .Rewards.ProductDescription .product-label .product-label-container .product-label-detail {
        display: flex;
        justify-content: flex-start;
        align-items: baseline; }
        .Rewards.ProductDescription .product-label .product-label-container .product-label-detail button {
          margin-left: 7.5px;
          background: transparent;
          border: none; }
        .Rewards.ProductDescription .product-label .product-label-container .product-label-detail img {
          width: 11.15px;
          height: 11.15px; }
  .Rewards.ProductDescription .product-description {
    margin-left: 26.5px;
    margin-right: 31.5px;
    margin-top: 20.5px; }
    .Rewards.ProductDescription .product-description .description-header {
      display: flex;
      justify-content: space-between; }
    .Rewards.ProductDescription .product-description .description-header-text {
      font: Bold 12.5px Roboto; }
    .Rewards.ProductDescription .product-description hr {
      border: 0.5px solid rgba(255, 255, 255, 0.1);
      margin-top: 5px;
      margin-bottom: 5px; }
    .Rewards.ProductDescription .product-description button {
      border: none;
      background: transparent;
      font-size: 15px; }
    .Rewards.ProductDescription .product-description p {
      font: Normal 12.5px Roboto; }
  .Rewards.ProductDescription .buy-container {
    margin-right: 34.5px;
    flex: 1;
    display: flex;
    justify-content: flex-end; }
    .Rewards.ProductDescription .buy-container .buy-button {
      border: none;
      border-radius: 25px;
      background: #6BE7CF 0% 0% no-repeat padding-box;
      color: #040826;
      height: 22px;
      width: 85px;
      padding: 5px;
      font: Bold 11px Roboto; }
    .Rewards.ProductDescription .buy-container .buy-button.disabled {
      background: #848484;
      cursor: not-allowed; }

.ProductDescription.purchase-confirmation {
  color: white;
  position: absolute;
  top: 0;
  background: transparent;
  height: 100%;
  width: 100%; }
  .ProductDescription.purchase-confirmation .close-button {
    position: absolute;
    top: 36px;
    right: 29px;
    background: transparent;
    border: none;
    z-index: 5; }
  .ProductDescription.purchase-confirmation .bold {
    font-weight: bold; }
  .ProductDescription.purchase-confirmation .purchase-content {
    position: absolute;
    top: 15%;
    width: 100%; }
  .ProductDescription.purchase-confirmation p {
    text-align: center;
    font: Normal 13px Roboto; }
  .ProductDescription.purchase-confirmation .purchase-info {
    margin-top: 33px;
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
    background: transparent linear-gradient(291deg, rgba(250, 245, 246, 0.28) 0%, rgba(252, 250, 250, 0.28) 60%, rgba(253, 253, 253, 0.28) 100%) 0% 0% no-repeat padding-box;
    border-radius: 22.5px;
    display: flex;
    flex-direction: column; }
    .ProductDescription.purchase-confirmation .purchase-info .available {
      text-align: left;
      margin: 22.5px 53.5px 0 53.5px;
      font-weight: bold; }
    .ProductDescription.purchase-confirmation .purchase-info .to-exchange {
      text-align: left;
      color: #FD3851;
      font-weight: bold;
      margin: 0 53.5px 0 53.5px; }
    .ProductDescription.purchase-confirmation .purchase-info .total {
      text-align: left;
      color: #6BE7CF;
      font-weight: bold;
      margin: 0 53.5px 0 53.5px; }
    .ProductDescription.purchase-confirmation .purchase-info .buy-button {
      margin: 25.3px 53.5px 19.5px 53.5px;
      border-radius: 20px;
      background: transparent linear-gradient(180deg, #FD3851 0%, #FE7163 100%) 0% 0% no-repeat padding-box;
      border: none;
      font-weight: bold;
      padding: 8.5px; }
    .ProductDescription.purchase-confirmation .purchase-info .value {
      float: right; }
    .ProductDescription.purchase-confirmation .purchase-info hr {
      margin: 10px 53.5px;
      border: 0.5px solid rgba(255, 255, 255, 0.28); }

@media only screen and (min-width: 600px) {
  .ProductDescription .product-info {
    border-radius: 40px; } }

.Rewards.AwardHistory {
  color: white; }
  .Rewards.AwardHistory hr {
    border: 0.5px solid #FD3851;
    width: 100%;
    margin-top: 11.3px;
    margin-bottom: 0; }
  .Rewards.AwardHistory .selector-bar {
    margin-top: -2.5px;
    width: 20%;
    height: 5px;
    background-color: #FD3851; }
  .Rewards.AwardHistory .text-content {
    margin-top: 25px;
    text-align: center; }
  .Rewards.AwardHistory .selector-bar.for {
    margin-left: 10%;
    animation-duration: 0.5s;
    animation-name: slideleft; }

@keyframes slideleft {
  from {
    margin-left: 50%; }
  to {
    margin-left: 10%; } }
  .Rewards.AwardHistory .selector-bar.from {
    margin-left: 50%;
    animation-duration: 0.5s;
    animation-name: slideright; }

@keyframes slideright {
  from {
    margin-left: 10%; }
  to {
    margin-right: 50%; } }
  .Rewards.AwardHistory .top-sub-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 15px; }
    .Rewards.AwardHistory .top-sub-nav .page-title {
      flex: 2;
      cursor: pointer; }
    .Rewards.AwardHistory .top-sub-nav .filter {
      flex: 1; }
      .Rewards.AwardHistory .top-sub-nav .filter button {
        background: transparent;
        border: none; }
      .Rewards.AwardHistory .top-sub-nav .filter img {
        height: 18px; }
  .Rewards.AwardHistory .points-badge {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 1.5px solid #FD3851;
    position: absolute;
    top: 0;
    right: 15%;
    background-color: #FD3851;
    padding: auto; }
    .Rewards.AwardHistory .points-badge p {
      height: 100%;
      padding: 12.5%;
      font: Normal 8px Roboto; }
  .Rewards.AwardHistory .medal-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .Rewards.AwardHistory .recognition-movement-container {
    margin-top: 31.75px;
    width: 33%;
    max-width: 150px;
    box-sizing: border-box;
    text-align: center; }
    .Rewards.AwardHistory .recognition-movement-container p {
      font: Italic 8px Roboto; }
    .Rewards.AwardHistory .recognition-movement-container .by-text {
      margin-top: 7.5px; }
  .Rewards.AwardHistory .medal-item .medal-details {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .Rewards.AwardHistory .medal-item .image-container {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 50%;
    display: inline-block;
    padding: 20px; }
    .Rewards.AwardHistory .medal-item .image-container img {
      width: 33.5px; }
  .Rewards.AwardHistory .medal-item .medal-badge {
    border: 1px solid #FD3851;
    border-radius: 25px;
    display: inline-block;
    padding: 2.5px 12.5px;
    margin-top: 8px;
    font-size: 10px; }

.Rewards.AwardHistory.blurred {
  /*
    width: 100vw;
    top: 0;
    position: absolute;
*/
  overflow: hidden;
  pointer-events: none;
  max-height: calc(100vh - 67.38px); }

.Rewards.admin {
  margin: 25px 18px 0 18px; }
  .Rewards.admin .add-medal-container {
    width: 100%; }

.ProductRequisitionDetail .but-left {
  border-radius: 5px 0 0 5px; }

.ProductRequisitionDetail .but-right {
  border-radius: 0 5px 5px 0; }

.ProductRequisitionDetail .receive-btn {
  margin-right: 15px; }

.ProductRequisitionDetail .status-title {
  font-size: 1.5em; }

.ProductRequisitionDetail .requisition-actions .cancel-btn {
  float: right; }

@media only screen and (max-width: 600px) {
  .ProductRequisitionDetail .requisition-actions {
    text-align: center; }
    .ProductRequisitionDetail .requisition-actions button {
      width: 90%;
      margin: 10px 0; }
    .ProductRequisitionDetail .requisition-actions .cancel-btn {
      float: none; } }

@media only screen and (max-width: 600px) {
  .ProductRequisitionDetail .receive-btn {
    margin-right: 15px; } }

.ProductRequisitionDetail .canceled-alert {
  width: 350px;
  margin: auto;
  background-color: #fb404b;
  text-align: center;
  color: white;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid red;
  font-size: 1.4em; }

.JazzDashboard hr {
  margin: 50px 0; }

.JazzDashboard .filters-panel {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  text-align: center; }

.JazzDashboard .quad {
  text-align: center; }
  .JazzDashboard .quad .quad-horizontal {
    height: 50%; }
  .JazzDashboard .quad .quad-vertical {
    width: 50%;
    height: 100%;
    display: inline-block;
    padding: 36px 0; }
    .JazzDashboard .quad .quad-vertical .stat-num {
      font-size: 40px; }
    .JazzDashboard .quad .quad-vertical .stat-name {
      font-weight: bold; }

.JazzDashboard .requisitions-quad {
  color: white; }
  .JazzDashboard .requisitions-quad h2 {
    color: #333333; }
  .JazzDashboard .requisitions-quad .tl {
    background-color: #188977; }
  .JazzDashboard .requisitions-quad .tr {
    background-color: #1D9A6C; }
  .JazzDashboard .requisitions-quad .bl {
    background-color: #74C67A; }
  .JazzDashboard .requisitions-quad .br {
    background-color: #BFE1B0; }

.JazzDashboard .total-amounts {
  text-align: center; }
  .JazzDashboard .total-amounts h4 {
    margin: 28px 0 0 0; }
  .JazzDashboard .total-amounts .stat {
    font-size: 1.4em; }

.JazzDashboard .top-providers {
  text-align: center; }
  .JazzDashboard .top-providers .provider-block {
    border: 1px solid #DDD; }
  .JazzDashboard .top-providers h4 {
    margin: 5px 0;
    font-weight: bold; }
  .JazzDashboard .top-providers .stat {
    font-size: 1.4em; }

.JazzDashboard .area-totals td, .JazzDashboard .area-totals th {
  text-align: center; }

.WarehouseCountDetail .count-input {
  display: inline-block;
  width: 180px; }

@media only print {
  .WarehouseCountDetail .hide-print-optional .print-optional {
    display: none; } }

.LoungeAccessMethodsList {
  padding: 15px; }
  .LoungeAccessMethodsList .access-method-block {
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.15);
    position: relative; }
    .LoungeAccessMethodsList .access-method-block .panel-title {
      margin: 0;
      font-size: 32px; }
      .LoungeAccessMethodsList .access-method-block .panel-title a {
        font-weight: lighter; }
    .LoungeAccessMethodsList .access-method-block .actions {
      position: absolute;
      right: 40px;
      z-index: 1; }
      .LoungeAccessMethodsList .access-method-block .actions button {
        background: none;
        border: none;
        font-size: 20px;
        margin: 0 6px; }
      .LoungeAccessMethodsList .access-method-block .actions .delete-button {
        color: #fb404a; }
      .LoungeAccessMethodsList .access-method-block .actions .edit-button {
        color: #7070dc; }
    .LoungeAccessMethodsList .access-method-block .data-block {
      text-align: center; }
      .LoungeAccessMethodsList .access-method-block .data-block h4 {
        margin: 30px 0 0 0; }
      .LoungeAccessMethodsList .access-method-block .data-block span {
        font-size: 40px; }
    .LoungeAccessMethodsList .access-method-block .under-box {
      padding: 18px; }
      .LoungeAccessMethodsList .access-method-block .under-box .sub-data-container {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15) inset;
        border-radius: 5px;
        padding: 15px; }
        .LoungeAccessMethodsList .access-method-block .under-box .sub-data-container h4 {
          margin: 0 0 10px 0; }
        .LoungeAccessMethodsList .access-method-block .under-box .sub-data-container ul {
          list-style: none;
          padding: 0; }
        .LoungeAccessMethodsList .access-method-block .under-box .sub-data-container li {
          display: inline-block;
          border: 1px solid #888888;
          border-radius: 5px;
          margin: 5px;
          padding: 6px 15px;
          background-color: #e6e6e6; }
          .LoungeAccessMethodsList .access-method-block .under-box .sub-data-container li.no-data {
            opacity: 0.3; }

.LoungeAccessMethodFormModal p.in-label {
  margin: 8px 0; }

.LoungeAccessMethodFormModal .config-list {
  list-style: none;
  padding: 0 0 0 25px; }
  .LoungeAccessMethodFormModal .config-list .config-label {
    display: inline-block;
    min-width: 160px; }
  .LoungeAccessMethodFormModal .config-list .text-input {
    max-width: 100px;
    position: relative;
    display: inline-block; }
    .LoungeAccessMethodFormModal .config-list .text-input.price:before {
      content: '$';
      position: absolute;
      top: 0.67em;
      left: 0.8em;
      color: darkgray; }
    .LoungeAccessMethodFormModal .config-list .text-input.number:before {
      content: '#';
      position: absolute;
      top: 0.67em;
      left: 0.8em;
      color: darkgray; }
    .LoungeAccessMethodFormModal .config-list .text-input input {
      padding-left: 1.8em; }

.LoungeAccessMethodFormModal .extra-field {
  padding: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative; }
  .LoungeAccessMethodFormModal .extra-field .extra-input-label {
    margin: 8px 0 2px 0; }
  .LoungeAccessMethodFormModal .extra-field .valid {
    background-color: #22e06d;
    color: white; }
  .LoungeAccessMethodFormModal .extra-field .invalid {
    background-color: #f35d5d;
    color: white; }
  .LoungeAccessMethodFormModal .extra-field .delete-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: none;
    background: none; }

.VisitsDashboard {
  /*
    .TimeStats{
        display: flex;
        .column{
            .cell{
                text-align: right;
                height: 60px;
                border-right: solid 1px rgba(0, 0, 0, 0.8);
                padding: 5px 10px;
            }
            .cell:nth-child(odd){
                background-color: rgba(110, 110, 110, 0.2);
            }
            .cell.title{
                //background-color: rgb(0, 0, 0);//rgba(50, 50, 50, 0.3);
                text-align: center;
                //font-weight: bold;
                //font-size: 15px;
            }
        }
    }
    */ }
  .VisitsDashboard .LoungeCurrentOcupancy {
    color: white;
    border-radius: 15px; }
  .VisitsDashboard .file-entry:hover {
    background: lightgrey; }
  .VisitsDashboard .file-entry {
    background: transparent;
    border-radius: 15px;
    padding: 0 15px;
    border: solid 1px gray;
    color: black;
    text-align: center;
    min-width: 260px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .VisitsDashboard .file-box {
    border-radius: 10px;
    padding: 0 20px; }
  .VisitsDashboard .file-container {
    display: flex;
    overflow-x: auto; }
  .VisitsDashboard .current-ocupancy-container {
    display: flex;
    justify-content: space-around; }
  .VisitsDashboard .date-picker-container {
    display: flex;
    justify-content: center; }
  .VisitsDashboard .date-stats-container {
    margin: 20px 15px;
    padding: 0 15px;
    border-left: 1px solid #a2a2a2;
    border-top: 1px solid #a2a2a2;
    border-right: 1px solid #a2a2a2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
  .VisitsDashboard .excel-download {
    margin-top: 40px;
    background-color: #0f3600;
    color: white;
    border-radius: 15px;
    text-align: center;
    display: inline-block;
    width: 100%; }
    .VisitsDashboard .excel-download h3 {
      margin-top: 20px; }
  .VisitsDashboard table {
    width: 100%; }
    .VisitsDashboard table th {
      border: 1px solid #c2c2c2 !important;
      text-align: center;
      font-size: 20px;
      padding: 15px 10px;
      background-color: #efefef; }
    .VisitsDashboard table tr:nth-child(even) {
      background-color: rgba(110, 110, 110, 0.2); }
    .VisitsDashboard table tbody {
      font-size: 15px; }
      .VisitsDashboard table tbody th {
        width: 20%; }
      .VisitsDashboard table tbody tr {
        padding-right: 15px; }
      .VisitsDashboard table tbody td.empty {
        color: lightgrey; }
      .VisitsDashboard table tbody td {
        padding-right: 5px; }

.TimePicker {
  margin: 30px;
  text-align: center; }
  .TimePicker button {
    border: 2px solid #252525;
    background: none;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 4px 8px;
    font-size: 26px;
    color: #252525;
    border-radius: 50px;
    line-height: 0; }
  .TimePicker .time-display {
    font-size: 40px; }

.UserPermissionGroups .label {
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block; }

.UserPermissionGroups .delete {
  color: #ff3726;
  cursor: pointer; }

.CashRegistersList .cash-closing-block {
  display: inline-block; }

.CashRegistersList .lounge-name {
  border-radius: 5px;
  background-color: black;
  color: white;
  padding: 10px; }

.CashRegistersList .single-register {
  display: inline-block;
  text-align: center;
  width: 200px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  margin-right: 10px; }
  .CashRegistersList .single-register i {
    font-size: 50px;
    width: initial; }
  .CashRegistersList .single-register:hover {
    background-color: lightgray; }

.CashRegisterDetailModal h3, .CashRegisterDetailModal h5 {
  margin-bottom: 35px;
  font-weight: normal; }

.CashRegisterDetailModal h3 {
  font-size: 25px; }
  .CashRegisterDetailModal h3 small {
    margin-left: 5px; }

.CashRegisterDetailModal h5 {
  margin-bottom: 10px; }

.CashRegisterDetailModal .current-money .payment-method {
  font-size: 20px; }
  .CashRegisterDetailModal .current-money .payment-method i {
    margin-right: 15px; }

.CashRegisterDetailModal .current-money .currencies .currency-detail svg {
  width: 18px;
  margin-right: 15px; }

.CashRegisterDetailModal .current-money .currencies .currency-detail .total {
  font-size: 20px; }

.CashRegisterDetailModal button.full-width {
  width: 100%;
  font-size: 25px; }

.CashRegisterDetailModal .table-currency-detail {
  margin-bottom: 10px;
  font-size: 16px; }
  .CashRegisterDetailModal .table-currency-detail i {
    margin-right: 10px; }
  .CashRegisterDetailModal .table-currency-detail svg {
    width: 15px;
    margin-right: 10px; }

.CashRegisterDetailModal .closings-history .header {
  position: relative; }
  .CashRegisterDetailModal .closings-history .header h3 {
    display: inline-block; }

.DishAdmin .product-picture {
  margin: 0 auto;
  width: 50%;
  border-radius: 8px;
  background-position: center;
  background-size: cover; }

.DishAdmin .product-picture::after {
  padding-bottom: 100%;
  content: "";
  display: inline-block; }

.DishAdmin.modal-product-picture {
  width: 100%; }

.ProviderPriceTable .title-desc {
  margin: 30px 0 0 0; }

.ProviderPriceTable .title {
  margin: 5px; }

.ProviderPriceTable .small-name {
  color: lightgray;
  font-size: 0.7em; }

.ProviderPriceTable .price-list-table .product-brand-block {
  margin: 5px;
  border: 1px solid #DDDDDD;
  padding: 15px;
  box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.22);
  border-radius: 8px; }
  .ProviderPriceTable .price-list-table .product-brand-block .product-photo {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background: center no-repeat;
    background-size: cover;
    float: left;
    margin-top: 30px; }
  .ProviderPriceTable .price-list-table .product-brand-block .bootstrap-switch {
    float: right; }
  .ProviderPriceTable .price-list-table .product-brand-block .name {
    font-size: 24px;
    text-align: center; }
  .ProviderPriceTable .price-list-table .product-brand-block .unit {
    text-align: center;
    margin: 5px 0 0 0; }
  .ProviderPriceTable .price-list-table .product-brand-block .prices {
    display: flex;
    justify-content: space-around;
    text-align: center; }
    @media only screen and (max-width: 600px) {
      .ProviderPriceTable .price-list-table .product-brand-block .prices .price-block {
        margin: 10px 0 0 0; }
      .ProviderPriceTable .price-list-table .product-brand-block .prices .price-type {
        display: inline-block;
        padding-top: 5px;
        border-top: 1px solid gray; } }
    .ProviderPriceTable .price-list-table .product-brand-block .prices .price-type, .ProviderPriceTable .price-list-table .product-brand-block .prices .expiration {
      color: #888888;
      cursor: pointer; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices .price {
      font-size: 28px;
      cursor: pointer; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices .edit-icon {
      font-size: 20px;
      color: #4cabff;
      position: absolute;
      opacity: 0; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices:hover .edit-icon {
      opacity: 1; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices:hover .CalendarPopUp i {
      color: #4cabff; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices .edit-price-block {
      display: inline-block; }
      .ProviderPriceTable .price-list-table .product-brand-block .prices .edit-price-block button {
        background: none;
        border: none;
        font-size: 16px;
        padding: 0;
        vertical-align: middle;
        height: 20px; }
      .ProviderPriceTable .price-list-table .product-brand-block .prices .edit-price-block .edit-price-input {
        width: 95px;
        border-radius: 5px;
        border: 0;
        box-shadow: inset 0 0 5px #b5b5b5;
        font-size: 20px;
        padding: 8px 8px; }
    .ProviderPriceTable .price-list-table .product-brand-block .prices .CalendarPopUp {
      display: inline-block; }
  @media only screen and (max-width: 600px) {
    .ProviderPriceTable .price-list-table .product-brand-block .prices {
      flex-direction: column; }
    .ProviderPriceTable .price-list-table .product-brand-block .product-photo {
      display: none; } }

.ProviderPriceTable .price-list-table .add-block {
  cursor: pointer; }
  .ProviderPriceTable .price-list-table .add-block:hover {
    background: #EEEEEE; }
  .ProviderPriceTable .price-list-table .add-block p {
    font-size: 20px;
    margin: 0; }

.CalendarPopUp-container {
  position: absolute; }

.CalendarPopUp-close-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.PurchaseInvoicesAndPayments .side-block {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  padding: 10px; }
  .PurchaseInvoicesAndPayments .side-block .total {
    float: right;
    margin-top: 10px; }
  .PurchaseInvoicesAndPayments .side-block .amount-cell {
    font-weight: bold; }
  .PurchaseInvoicesAndPayments .side-block .file-btn, .PurchaseInvoicesAndPayments .side-block .delete-btn, .PurchaseInvoicesAndPayments .side-block .edit-btn {
    border: none;
    background: none; }
  .PurchaseInvoicesAndPayments .side-block .file-btn img {
    width: 35px; }

.Configuration .configuration-container {
  padding: 30px 30px 30px 30px;
  background-color: white;
  border-radius: 5px;
  margin-top: 10px; }

.Configuration .config-editor {
  border-bottom: 1px solid lightgrey; }
  .Configuration .config-editor .description {
    font-size: 16px;
    float: left; }
  .Configuration .config-editor .value {
    float: left; }

.Configuration .btn-success {
  margin-top: 15px;
  float: right; }

.Configuration .config-editor {
  margin-bottom: 15px;
  padding-bottom: 15px; }

.PurchaseRequisitionApprovals .coApprover {
  margin-top: 15px; }

.ServicesAdmin .services-list {
  margin: 0 20px; }

.POExpectedDate .edit-btn {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #2c6cf6; }

.ProductReport .header .image-container {
  float: right; }
  .ProductReport .header .image-container .product-image {
    max-width: 160px;
    max-height: 160px;
    border-radius: 5px; }

.ProductReport .header h1 {
  margin-bottom: 0; }

.ProductReport .header h4 {
  margin: 0; }

.ProductReport .date-picker {
  text-align: center; }
  .ProductReport .date-picker p {
    font-size: 2em; }

.ProductReport .graphs-block {
  display: flex;
  background: white;
  margin-top: 25px; }
  .ProductReport .graphs-block .left-handles {
    width: 200px;
    display: flex;
    flex-direction: column; }
    .ProductReport .graphs-block .left-handles .handle {
      flex-grow: 1;
      text-align: center;
      padding: 10px;
      background-color: gainsboro;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .ProductReport .graphs-block .left-handles .handle.selected {
        background-color: white; }
      .ProductReport .graphs-block .left-handles .handle:not(.selected):hover {
        cursor: pointer;
        background-color: #c4c4c4;
        box-shadow: inset 0 0 15px 5px #888888; }
      .ProductReport .graphs-block .left-handles .handle .name {
        font-size: 1.4em;
        color: grey; }
      .ProductReport .graphs-block .left-handles .handle .data {
        font-size: 1.8em; }
      .ProductReport .graphs-block .left-handles .handle .daily {
        color: grey; }
  .ProductReport .graphs-block .graph-container {
    flex-grow: 1; }

.ProductReport .providers-block {
  margin-top: 30px;
  background-color: white;
  padding: 15px; }

.DavinciReports .report-cont {
  padding: 10px; }

.DavinciReports .report-block {
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid gray;
  padding: 10px; }
  .DavinciReports .report-block h4 {
    margin: 5px 0; }

.AppointmentFormModal .in-label {
  margin: 15px 0; }

.AppointmentFormModal hr {
  margin: 10px 0 0 0; }

.AppointmentFormModal .day-off {
  display: inline-block;
  margin: 5px;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer; }
  .AppointmentFormModal .day-off i {
    color: #E3E3E3; }

.LoungeMapList .map-block {
  padding: 15px;
  cursor: pointer; }
  .LoungeMapList .map-block h2 {
    padding: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px; }
  .LoungeMapList .map-block:hover h2 {
    border-color: black; }

.VisitDetailModal .guest {
  margin: 15px 0; }

.VisitDetailModal .lounge {
  text-align: right; }

.VisitDetailModal .lounge, .VisitDetailModal .date {
  font-size: 1.4em; }
