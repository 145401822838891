.PurchaseProductAnalysis{
  .product-title{
    margin: 0;
    text-align: center;
  }
  .info-box{
    border: 1px solid #e5e5e5;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 10px 0;
    .box-title{
      font-size: 1.2em;
      margin: 0;
    }
    &.large .box-title{
      font-size: 1.6em;
      margin: 4px 0 10px 0;
    }
    .box-value{
      font-size: 2em;
      margin: 0;
    }
    .box-link{
      margin: 0;
    }
  }
  .inventory-box{
    text-align: left;
  }
}
