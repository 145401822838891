@keyframes bar-fill {
    0% { width: 0; color: transparent; }
}

.WidgetUsedBudget {
    width: 60%;
    margin: 1.5em 1.5em;
    padding: 1em 0.1em 1em 0.1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    .title {
        text-transform: uppercase;
        font: 300 1.5em sans-serif;
        text-transform: uppercase;
            
        strong {
          font-weight: 400;
          //background: linear-gradient(to bottom, rgb(181, 135, 113) 75%, rgb(173, 168, 106) 100%);
          color: #73A0B5;
        }
    }
    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .card {
            width: 400px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            .card-header {
                font-size: 20px;
                padding: 0.5em 0;
                background-color: rgba(0,0,0,.03);
                border-bottom: 1px solid rgba(0,0,0,.125);
            }
            .card-footer {
                font-size: 20px;
                padding: 0.5em 0 0.2em;
                background-color: rgba(0,0,0,.03);
                border-top: 1px solid rgba(0,0,0,.125);
            }
        }
    }
    .bar-graph {
        list-style: none;
        margin: 0 auto;
        padding: 0 2em;
        .graph-box {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 1em;
            .text-name {
                margin: 0.5em 0 0.2em 0;
                width: 10%;
            }
            .text-budget {
                margin: 0.5em 0 0.2em 0;
                width: 20%;
                text-align: end;
            }
        }
        .bar-wrap {
            border-radius: 5px;
            margin: 0 1em;
            width: 70%;
            position: relative;
            background-color: rgba(0, 155, 202, 0.2);          
            border: 1px solid rgba(0, 155, 202, 0.8);
            .text-usedwidget {
                color: white;
                position: absolute;
                width: 100%;
                text-align: center;
                margin: 0;
            }
        }
    
        .bar-fill {
            border-radius: 5px;
            animation: bar-fill 1s;
            display: block;
            padding-top: 0.1em;
            font-weight: bolder;
            text-align: center;
            color: white;
            height: 20px;
            width: 0px;
        }
    
        .bg-blue {
            background-color: #73A0B5;
        }
    
        .bg-orange {
            background-color: #73A0B5;
        }
    
        .bg-red {
            background-color: #73A0B5;
        }
    }
}