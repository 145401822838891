.GolfCartTripForm{
  .trip-time{
    display: flex;
    padding: 15px;
    border: 1px solid #EEE;
    margin: 15px;
    .trip-stat{
      flex: 1;
      text-align: center;
      font-size: 1.2em;
    }
    .trip-time-value{
      font-size: 1.8em;
    }
  }
  .footer {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }

  .global-status-container {
    margin-top: 20px;
  }

  .status-container {
    display: flex;
  }

  .status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .status-text {
    color: #afacac;
    margin-left: 8px;
  }

  .scheduled {
    background-color: #808080;
  }

  .in-progress {
    background-color: #7CFC00FF;
  }

  .delayed {
    background-color: #62c1ef;
  }
}
