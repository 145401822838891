.RecipeEditor{
  padding: 15px;
  .recipe-container{
    background: white;
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 15px;

    .recipe-subtitle{
      font-size: 1.5em;
    }
    .ingredients-table{
      margin: 0;
    }
    .add-button{
      width: 100%;
      display: inline-block;
      padding: 5px;
      font-size: 1.3em;
    }
  }
  .bottom-btn{
    margin: 15px;
  }
  /* Print media query for this and all child components will be added here */
  @media print{
    .recipe-container {
      padding: 0;
      border: none;
      table td{
        padding: 0;
      }
    }
    button{
      display: none;
    }

    .RecipeHeader{
      .left{
        border: none;
        border-radius: 0;
      }
      .approve-btn, .edit-btn{
        display: none;
      }
      .image-container{
        border-radius: 0;
      }
    }
    .RecipeIngredientsTable{
      .add-button{
        display: none;
      }
    }
    .RecipeFooter{
      .instructions{
        padding: 0;
        font-size: 1em;
        .text{
          font-family: inherit;
        }
      }
    }
    .RecipeGallery, .RecipeModifiers{
      display: none;
    }
  }
}
