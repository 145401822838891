.LoungeMapEditorTools{
  width: 300px;
  background-color: #3D79FF;
  border-radius: 10px;
  padding: 10px;
  color: white;

  &::selection, *::selection {
    background-color: #ffaff9;
    color: #1f1f1f;
  }

  .title{
    text-align: center;
    margin: 0;
  }

  .toolbox-title{
    cursor: pointer;
  }
  .toolbox{
    border-radius: 10px;
    border: 1px solid white;
    padding: 5px;
    .toolbox-label{
      display: inline-block;
      font-weight: bold;
      width: 60px;
      margin: 10px 0;
    }
    .toolbox-input{
      background: none;
      border: 1px solid white;
      border-radius: 5px;
      padding: 4px;
      width: 195px;

      &.full-width{
        display: block;
        width: 100%;
      }
    }
    option{
      color: black;
    }

  }
  .toolbox-button{
    border: 1px solid white;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
    background: none;
    display: inline-block;
    width: calc(50% - 10px);

    &.full-width{
      width: 100%;
      margin: 0;
    }

    &.active{
      color: black;
      background-color: white;
      box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .toolbox-button.save-btn{
    margin: 10px 0;
  }
}
