.WarehouseNotifyTable {
    border: 1px solid gray;
    border-radius: 4px;
    margin: 15px 0;
  
    .p-header {
      border-bottom: 1px solid lightgray;
      padding: 15px;
    }
  
    .perm-title {
      font-size: 1.3em;
    }
  
    .select-cont {
      display: flex;
  
      .new-employee-select {
        flex: 1;
      }
  
      .btn {
        vertical-align: text-bottom;
      }
    }
  
    .empty-msg {
      padding: 15px;
      text-align: center;
      color: gray;
    }
}
  