.GolfCartPassengerInput{
  padding: 15px;
  border: 1px solid #EEE;
  border-radius: 5px;
  margin: 15px 0;
  position: relative;
  .remove-button{
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    color: lightgrey;
  }
}
