.QuestionnaireStats {
    @media print {
        .filtersContainer {
            display: none;
        }
        .pageBreak {
            page-break-after: always !important;
            border: 3px solid red;
            margin-bottom: 100px;
        }
        .pagebreak {
            clear: both;
            page-break-after: always;
        }
        .questionContaine {
            .card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }
        }
    }
}