.GolfCartScheduleDetail{
    padding: 20px 15px 0;
    height: calc( 100vh - 97px );
    display: block;

    @media screen and (min-width: 768px) {
        display: flex;
    }

    .GolfCartScheduleEditControl{
        flex: 2;

        @media screen and (min-width: 768px) {
            margin-right: 15px;
        }
    }
    .GolfCartSchedule{
        flex: 3;

        @media screen and (max-width: 768px) {
            padding-bottom: 15px;
        }
    }
}

