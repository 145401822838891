.TimeSelector{
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
  border-radius: 4px;
  padding: 8px 12px;
  height: 40px;
  display: flex;
  .time-input{
    border: none;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;
    display: block;
    text-align: center;
  }
  .time-colon{
    transform: scale(2);
  }
}
