.RecipeGallery{
  .gallery-list{
    padding: 0;
  }
  .gallery-item{
    list-style: none;
    display: inline-block;
    width: 260px;
    height: 260px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    margin: 10px;
    cursor: pointer;
    vertical-align: bottom;
    position: relative;
    .delete-btn{
      font-size: 25px;
      position: absolute;
      display: none;
      right: 10px;
      top: 10px;
    }
    &:hover{
      opacity: 0.8;
      .delete-btn{
        display: block;
      }
    }
  }
  .add-block{
    text-align: center;
    position: relative;
    padding-top: 110px;
    background-color: #cccccc;
    .fa{
      font-size: 40px;
      width: 40px;
    }
    .image-input{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }

  .image-visor{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    z-index: 100;
    .big-image{
      width: 80vw;
      height: 80vh;
      margin: 10vh 10vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .arrow, .close-but{
      position: absolute;
      bottom: 4vh;
      background: none;
      border: none;
      .fa {
        font-size: 5em;
        width: 1em;
        color: white;
      }
      &.left{
        left: 4vw;
      }
      &.right{
        right: 4vw;
      }
    }
    .close-but{
      top: 4vh;
      right: 4vw;
      bottom: initial;
    }
  }
}
