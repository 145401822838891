.RadioSelector{
  border: 2px solid grey;
  border-radius: 11px;
  overflow: hidden;
  display: inline-block;

  .option-container{
    display: inline-block;
    border-right: 1px solid lightblue;
    text-align: center;
    transition: background-color 0.3s;
    &:last-child{
      border-right: none;
    }
    &.selected{
      background-color: grey;
      color: white;
    }
    label{
      padding: 10px;
      display: inline-block;
      cursor: pointer;
      margin-bottom: 0;
    }
    input{
      display: none;
    }
  }
}
