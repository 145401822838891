.GolfCartDayControl{
  padding: 20px 15px;
  height: calc( 100vh - 120px);
  .date-bar{
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    font-size: 1.4em;
    background-color: white;
    .change-date-but{
      //font-size: 2em;
      background: none;
      border: none;
    }
  }

  .work-space{
    height: 100%;
    .GolfCartScheduleControlPanel{
      flex: 1;
      margin: 5px;
    }
    .GolfCartSchedule{
      flex: 1;
      margin: 5px;
      &.selected{
        box-shadow: 0px 0 20px 6px #7990ba;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
}
