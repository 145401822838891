.FlightsTable {
    .current-flights, .flight-filters {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }
    .inactive{
        background: #fb404b;
    }
    .current-flights {
        margin-bottom: 2%;
    }
    .flight-filters {
        flex-basis: 50%;
        .searchInput, .flight-type-selector {
            margin: 0 2px;
            width: 100%;
            .label { color: black; }
        }
        .searchInput {
            border: 1px solid lightgray;
            display: block;
            padding: 4px;
        }
        .flight-type-selector  .Select-clear-zone{
            display: none;
        }
    }
    .edit-action {
        background: none;
        border: none;
        margin: auto;
        padding: 0;
        width: 100%;
        opacity: .7;
        transition: all .2s ease-in-out;
        outline: none;
        &:hover {
            opacity: 1;
        }
    }
}
