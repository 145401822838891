.GolfCartSchedule{
  display: flex;
  flex-direction: column;
  min-width: 180px;
  .header{
    background-color: #444;
    padding: 15px;
    font-size: 1.4em;
    text-align: center;
    color: white;
    border-radius: 5px 5px 0 0;
    position: relative;
  }
  .time-space{
    background-color: white;
    flex: 1;
    overflow-y: auto;
    position: relative;
    $time-block-padding: 10px;
    display: block;

    @media screen and (max-width: 768px) {
      &--hidden{
        display: none;
      }
    }

    .time-block{
      padding: $time-block-padding;
      border-bottom: 01px solid #cbcbcb;
      position: relative;
      .time-label{
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        background-color: rgba(255,255,255,0.6);
        border-radius: 0 0 4px 0;
        padding: 4px;
      }

      .time-indicator {
        left: 50%;
        top: 50%;
        width: 100%;
        position: absolute;
        border-top: 3px solid red;
        transform: translateX(-50%);

        &::before {
          content: '';
          width: 15px;
          height: 15px;
          position: absolute;
          top: -8px;
          right: 0;
          border-radius: 50%;
          background-color: red;
        }
      }
    }
    .scheduledTrip{
      position: absolute;
      border-radius: 10px;
      left: 5%;
      width: 90%;
      text-align: center;
      padding: 5px;
      color: white;
      /*background: linear-gradient(to bottom, rgba(31, 141, 255, 0.63) 75%, rgba(147, 243, 255, 0.76) 100%);*/
      cursor: pointer;
      &.editing{
        border: 2px solid #447df7;
        box-shadow: inset 0 0 15px -4px black;
      }

      @keyframes ongoinganimation{
        from {background-color: white;}
        to {background-color: lawngreen;}
      }

      .current-passengers {
        width: 50px;
        background: gray;
        border-radius: 5px;
        i {
          margin-right: 5px;
        }
        .red {
          color: red;
        }
      }

      .indicator{
        top: -15px;
        right: -5px;
        width: 25px;
        height: 25px;
        background: gray;
        position: absolute;
        border-radius: 40px;
        border: 2px solid white;
        z-index: 200;
        &.ongoing{
          animation: ongoinganimation 1s infinite;
        }
        &.ended{
          background-color: #62c1ef;
        }
      }
      .comments-button{
        top: -15px;
        right: 22px;
        width: 25px;
        height: 25px;
        background: #3DB94E;
        position: absolute;
        border-radius: 40px;
        z-index: 200;
        border: 2px solid white;
      }
    }
    /*.trip{
      background: rgba(31, 141, 255, 1);
    }*/
    .manually-created{
      background: #0f4e8f;
    }
    .trip-preview {
      position: absolute;
      border-radius: 10px;
      left: 5%;
      width: 90%;
      text-align: center;
      padding: 5px;
      color: white;
      background: #000;

      .current-passengers-preview {
        position: absolute;
        top: 3px;
        left: 25px;
      }
    }
  }

  .scheduledTrip-info-container{
    display: flex;
    justify-content: space-between;
  }

  .scheduledTrip-lounge{
    width: calc(100% - 50px - 5px);
    background-color: black;
    border-radius: 5px;
  }

  .current-passengers{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-command{
    display: none;
  }

  @media screen and (max-width: 768px) {
    .btn-command{
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}
