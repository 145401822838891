.InventoryMinMax {
    .container-box {
        display: flex;
        align-items: flex-end;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: center;
        }
        .min-box {
            display: flex;
            padding: 2em;
            flex-direction: column;
            align-items: center;
            .input-box {
                display: flex;
                justify-content: space-between;
                .info-box {
                    border: 1px solid gray;
                    border-radius: 5px;
                    width: 150px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        margin: 0;
                    }
                }
                .quantity-show {
                    margin-right: 0.5em;
                }
                .display-none {
                    display: none !important;
                }
                .select-box {
                    padding-left: 0.5em;
                    width: 150px;
                    height: 40px;
                    border-radius: 5px;
                    border: 1px solid gray;
                }
                .input-number {
                    margin-right: 0.5em;
                    padding-left: 0.5em;
                    width: 150px;
                    height: 40px;
                    border-radius: 5px;
                    border: 1px solid gray;
                }
                .unit-show-input {
                    display: flex;
                    flex-direction: column;
                }
                .unit-show {
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
        .button-box {
            .edit-button {
                background-color: slateblue;
                color: white;
                border: none;
                i {
                    margin-right: 5px;
                }
            }
            .save-button {
                background-color: green;
                color: white;
                border: none;
                i {
                    margin-right: 5px;
                }
            }
        }
    }
}