.NewPasswordConfirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;

  .content-wrapper {
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    box-shadow: 1px 1px 1px black;
    background-color: white;

    .passwordText {
      font-size: 5rem;
      font-weight: 700;
    }
  }
}