
$form-width: 400px;
.TideFormModal{
  .fields {
    margin: auto;
    max-width: $form-width;
  }
  .errors-block{
    max-width: $form-width;
    margin: auto;
    text-align: center;
    border: 1px solid red;
    border-radius: 4px;

    .error{
      color: red;
      background-color: lightgray;
      font-size: 0.9em;
      border-radius: 4px;
      margin: 0.4em;
    }
  }
  .input{
    width: 100%;
    font-size: 1.1em;
    padding: 0.2em;
    margin: 5px 0;
  }
}
